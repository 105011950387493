import './App.css';
import './mobile.css';
import { PropagateLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import Home from './components/Home';
import About from './components/About';
import Platform from './components/Platform';
import Solutions from './components/Solutions';
import Services from './components/Services';
import Team from './components/Team';
import Careers from './components/Careers';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import SMS from './components/SMS';
import Contact from './components/Contact';
import ITWorkflows from './components/ITWorkflows';
import EmployeeWorkflows from './components/EmployeeWorkflows';
import CustomerWorkflows from './components/CustomerWorkflows';
import Whoweare from './components/About/whower';
import Life from './components/life';
import Esm from './components/ESM';
import Service from './components/ServicesTest';
import Devops from './components/Devops';
import ITService from './components/ISS';
import GRC from './components/GRC';
import ITAM from './components/ITAM';
import Testing from './components/Testing';
import Support from './components/Support';
import ITSM from './components/ITSM';
import ITBM from './components/ITBM';
import ITOM from './components/ITOM';
import HRS from './components/HRS';
import WSD from './components/WSD';
import LSD from './components/LSD';
import CSM from './components/CSM';
import FSM from './components/FSM';
import TSM from './components/TSM';
import Leader from './components/Leader';
import Learn from './components/Learn';
import Privacy from './components/privacyPolicy';
import Terms from './components/TermsOfUse';
import Cookies from './components/Cookies';

export default function App() {
  const [loading, setLoading] = useState(() => false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (
    <BrowserRouter>
      {loading ?
        <div className='spinner'>
          <PropagateLoader color='#0c68e0' />
        </div>
        :
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path="/solutions" element={<Esm />} />
          {/* <Route exact path="/team" element={<Team />} /> */}
          <Route exact path="/careers" element={<Careers />} />
          <Route exact path="/about/whoweare" element={<Whoweare />} />
          {/* <Route exact path="/about/whoweare" element={<About />} /> */}
          <Route exact path="/about/ourleadership" element={<Leader />} />
          <Route exact path="/about/life@dotsito" element={<Life />} />
          <Route exact path="/about/learn" element={<Learn />} />
          {/* <Route exact path="/about/life@dotsito" element={<SMS />} /> */}
          {/* <Route exact path="/about/leadership" element={<About />} /> */}
          <Route exact path="/solution/serviceNowplatform" element={<Platform />} />
          <Route exact path="/solution/sms" element={<SMS />} />
          {/* <Route exact path="/service" element={<Services />} /> */}
          <Route exact path="/services" element={<Service />} />
          <Route exact path="/solution/Devops" element={<Devops />} />
          <Route exact path="/solution/ITSecurity" element={<ITService />} />
          <Route exact path="/solution/GRC" element={<GRC />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/solution/QA&Testing" element={<Testing />} />
          <Route exact path="/solution/ServiceNow_Support" element={<Support />} />
          {/* <Route exact path="/solutions/itWorkflows" element={<ITWorkflows />} /> */}
          <Route exact path="/service/itsm" element={<ITSM />} />
          <Route exact path="/service/itbm" element={<ITBM />} />
          <Route exact path="/service/itom" element={<ITOM />} />
          <Route exact path="/solution/itam" element={<ITAM />} />


          <Route exact path="/service/HRS" element={<HRS />} />
          <Route exact path="/service/WSD" element={<WSD />} />
          <Route exact path="/service/LSD" element={<LSD />} />

          <Route exact path="/service/CSM" element={<CSM />} />
          <Route exact path="/service/FSM" element={<FSM />} />
          <Route exact path="/service/TSM" element={<TSM />} />

          <Route exact path="/solution/esm" element={<Esm />} />
          <Route exact path="/solutions/employeeworkflows" element={<EmployeeWorkflows />} />
          <Route exact path="/solutions/customerWorkflows" element={<CustomerWorkflows />} />

          <Route exact path="/PrivacyPolicy" element={<Privacy />} />
          <Route exact path="/Terms&Conditions" element={<Terms />} />
          <Route exact path="/CookiesPolicy" element={<Cookies />} />
        </Routes>
      }
    </BrowserRouter>
  )
};

// color="#36d7b7"