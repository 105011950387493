import Navbar from './Navbar';
import Footer from './Footer';

export default function ITBM(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-12 text-center itbm--content--image title--edit p-3'>IT Business Management</h1>
                </div>
                <h5 className='col-sm-10 mt-4 itbm--content--text title--edit p-3'> Help to align IT resources, projects, and investments with the overall business strategy and goals of the organization. ITBM encompasses a set of 
                practices and tools that enable effective decision-making, resource optimization, and value delivery through IT services and projects. </h5>
                <div className='itbm--background--image'>
                </div>
                <div className='col-sm-12  mt-5 about--quotes--head'>
                    <p>IT Business Management (ITBM)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itbm--practice--align'>
                                <p style={{fontSize:"25px"}}>Business-IT Alignment</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> ITBM ensures that IT initiatives, projects, and services are closely aligned with the business objectives and priorities of the organization. 
                                It helps bridge the gap between IT and business stakeholders.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itbm--practice--align'>
                                <p style={{fontSize:"25px"}}>IT Portfolio Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>ITBM provides visibility and control over the entire IT portfolio, including projects, applications, services, and investments. It helps prioritize 
                                projects based on business value and resource constraints.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itbm--practice--align'>
                                <p style={{fontSize:"25px"}}>Cost Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>ITBM includes cost management practices to track and control IT-related expenses, ensuring that budgets are allocated wisely and cost-effectively.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itbm--practice--align'>
                                <p style={{fontSize:"25px"}}>Demand Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> ITBM involves managing the demand for IT services and projects, ensuring that they are in line with business needs and available resources.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 itbm--practice--align'>
                    <p style={{fontSize:"25px"}}>Value Management</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}>ITBM focuses on delivering value to the business through IT investments and projects. It ensures that IT initiatives contribute to business outcomes and growth.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 itbm--key--style'>
                    <div className='itbm--practice--align mt-5'>
                        <p style={{fontSize:"25px"}}>Key Capabilities Of ITBM</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itbm--practice--align'>
                                <p style={{fontSize:"18px"}}>Project Portfolio Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itbm--practice--align'>
                                <p style={{fontSize:"18px"}}>Application Portfolio Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itbm--practice--align'>
                                <p style={{fontSize:"18px"}}>Financial Planning</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itbm--practice--align'>
                                <p style={{fontSize:"18px"}}>Agile and Test Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itbm--practice--align'>
                                <p style={{fontSize:"18px"}}>Demand and Resource Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}