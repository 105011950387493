import React from 'react';
import collab from './collaboration.png';
import cl from './cl.png';
import account from './accountability.png';
import result from './results-dr.png';
import empower from './empower.png';


export default function CoreEdit() {
    return (
        <>
            <div className='col-sm-12'>
                <div className='row'>
                    <div className='col-sm-4 coreedit--alignment'>
                        <div className='col-sm-6 '>
                            <p className='coreedit--font text-center'>What We Provide at</p>
                        </div>
                        <div className='col-sm-6 mt-5 text--stroke'>
                            <p>DOTSITO</p>
                        </div>  
                    </div>

                    <div className='col-sm-8 coreedit--back mt-5 p-4'>
                        <div className='row '>
                            <div className='col-sm-4'>
                                <img src={collab} width={300} style={{borderRadius: "5px"}} alt="" />
                                <p style={{fontSize:"25px"}}>Collaboration</p>
                                <p style={{fontWeight: 400,fontSize:"18px"}}> Promoting teamwork and open communication to leverage collective expertise, solve challenges, and deliver comprehensive solutions.</p>
                            </div>
                            <div className='col-sm-4 f-align'>
                                <img src={cl} width={300} style={{borderRadius: "5px"}} alt="" />
                                <p style={{fontSize:"25px"      }}>Continuous Learning</p>
                                <p style={{fontWeight: 400,fontSize:"18px"}}> Committing to ongoing professional development and staying updated with industry trends, allowing the company and its employees 
                                to stay ahead of the curve.</p>
                            </div>
                            <div className='col-sm-3 i-align'>
                                <img src={account} width={300} style={{borderRadius: "5px"}} alt="" />
                                <p style={{fontSize:"25px",color:"#000"}} className='i--align--font'>Accountability</p>
                                <p style={{fontWeight: 400,fontSize:"18px",color:"#000"}} className='i--align--font'>  Taking ownership of commitments, actions, and outcomes, and being responsible for delivering on promises made to clients and partners.</p>
                            </div>
                            <div className='col-sm-4 a-align'>
                                <img src={result} width={300} style={{borderRadius: "5px"}} alt="" />
                                <p style={{fontSize:"25px"}}>Empowerment</p>
                                <p style={{fontWeight: 400,fontSize:"18px"}}> Empowering employees to take initiative, make decisions, and contribute meaningfully to the success of projects and the organization.</p>
                            </div>
                            <div className='col-sm-4 a-align'>
                                <img src={empower} width={300} style={{borderRadius: "5px"}} alt="" />
                                <p style={{fontSize:"25px"}}>Results-Driven</p>
                                <p style={{fontWeight: 400,fontSize:"18px"}}>  Focusing on delivering measurable outcomes and value to clients, striving to exceed expectations and achieve tangible results.</p>
                            </div>
                        </div>
                    </div>


                    {/* <div className='col-sm-8 c'>
                        <table class="table col-sm-12 table-borderless mt-5">
                            <tbody className=''>
                                <tr className=''>
                                    <td rowSpan={2}>
                                        <div className='col-sm-12'>
                                            <img src={expertise} width={300} style={{borderRadius: "5px"}} alt="" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='col-sm-12'>
                                            <p style={{color:"#fff",fontSize:"25px"}}>Expertise</p>
                                            <p style={{fontWeight: 400,color:"#fff",fontSize:"18px"}}> Our team comprises top-tier professionals with a diverse range of skills and knowledge.</p>
                                        </div>
                                    </td>
                                    <td rowSpan={2}>
                                        <div>
                                            <img src={centricity} width={300} alt="" />
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div className='col-sm-12'>
                                            <p style={{color:"#fff",fontSize:"25px"}}>Focus</p>
                                            <p style={{fontWeight: 400,color:"#fff",fontSize:"18px"}}> Our clients are at the heart of everything we do. We listen, understand, and prioritize their needs to deliver tailored 
                                            solutions that exceed expectations.</p>
                                        </div>
                                    </td>
                                    <td rowSpan={2}>
                                        <div>
                                            <img src={innovation} width={300} alt="" />
                                        </div>
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div className='col-sm-12'>
                                            <img src={integrity} width={300} alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className=''>
                                            <p style={{color:"#fff",fontSize:"25px"}}>Innovation</p>
                                            <p style={{fontWeight: 400,color:"#fff",fontSize:"18px"}}>  Embracing change and staying ahead of the curve is in our DNA. We continuously explore new technologies and industry trends 
                                            to offer innovative solutions that give our clients a competitive edge.</p>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='col-sm-12'>   
                                            <img src={agility} width={300} alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className='col-sm-12'>
                                            <p style={{color:"#fff",fontSize:"25px"}}>Integrity</p>
                                            <p style={{fontWeight: 400,color:"#fff",fontSize:"18px"}}>  Honesty and transparency form the foundation of our interactions with clients, partners, and team members. We believe 
                                            in building trust through ethical business practices.</p>
                                            <p></p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className=''>
                                            <p style={{color:"#fff",fontSize:"25px"}}>Agility</p>
                                            <p style={{fontWeight: 400,color:"#fff",fontSize:"18px"}}>  In the fast-paced world of technology, adaptability is crucial. We pride ourselves on our ability to be agile and responsive
                                             to the ever-changing demands of the industry.</p>      
                                            <p></p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                </div>
            </div>
        </>
    );
}
