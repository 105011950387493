import Navbar from './Navbar';
import Footer from './Footer';

export default function HRS(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7 text-center hr--content--image title--edit p-3'>HR Service Delivery</h1>
                </div>
                <h5 className='col-sm-10 mt-4 hr--content--text title--edit p-3'> To effectively manage and streamline the delivery of human resources (HR) services and processes to employees and the organization as a whole. HR Service Delivery aims 
                to enhance the overall employee experience, improve HR operational efficiency, and ensure compliance with HR policies and regulations.</h5>
                <div className='hr--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p>HR Service Delivery</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 hr--practice--align'>
                                <p style={{fontSize:"25px"}}>Employee Self-Service</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>HR Service Delivery provides employees with self-service capabilities, allowing them to access HR information, submit requests, 
                                and manage their HR-related tasks independently.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 hr--practice--align'>
                                <p style={{fontSize:"25px"}}>HR Policy and Compliance</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>HR Service Delivery ensures that HR policies and procedures are communicated effectively to employees, and compliance with employment laws 
                                and regulations is maintained.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 hr--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Employee Data Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>HR Service Delivery manages employee data and information, ensuring accuracy, confidentiality, and compliance with data privacy regulations.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 hr--practice--align'>
                                <p style={{fontSize:"25px"}}>Training and Development</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> HR Service Delivery helps manage training and development programs, including enrollment, tracking progress, and recording completion.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 hr--practice--align'>
                    <p style={{fontSize:"25px"}}>HR Document Management</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}> HR Service Delivery provides a centralized repository for HR documents, making it easy to access and manage employee records and documents securely.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 hr--key--style'>
                    <div className='hr--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of HR Service Delivery</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 hr--practice--align'>
                                <p style={{fontSize:"18px"}}>Case & Knowledge Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 hr--practice--align'>
                                <p style={{fontSize:"18px"}}>Enterprise Onboarding</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 hr--practice--align'>
                                <p style={{fontSize:"18px"}}>Virtual Agent Chatbot</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 hr--practice--align'>
                                <p style={{fontSize:"18px"}}>Employee Journey Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 hr--practice--align'>
                                <p style={{fontSize:"18px"}}>Process Optimization</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 hr--practice--align'>
                                <p style={{fontSize:"18px"}}>Employee Center</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}