import Navbar from './Navbar';
import Footer from './Footer';

export default function Testing(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7 text-center test--content--image title--edit p-3'>QA And Testing</h1>
                </div>
                <h5 className='col-sm-10 mt-4 test--content--text title--edit p-3'>To ensure that software products and solutions meet the desired quality standards before they are deployed to production or delivered to clients. QA and Testing are integral
                 components of the software development lifecycle and play a crucial role in delivering reliable, high-quality, and bug-free software.</h5>
                <div className='test--background--image'>
                </div>
                <div className='col-sm-12 text-center mt-5 about--quotes--head'>
                    <p>QA <span style={{color: "#1D61E7"}}>&</span> Testing</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 test--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Detecting and Preventing Defects</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>QA and Testing help identify defects, bugs, and issues in software applications early in the development process. By detecting and fixing these problems before deployment,
                                 the company can prevent costly and time-consuming issues in production.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 test--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Maintaining Quality Standards</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>QA and Testing ensure that software products adhere to established quality standards, industry best practices, and client requirements. This consistency in quality 
                                builds trust with clients and end-users.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 test--practice--align'>
                                <p style={{fontSize:"25px"}}>Compliance and Security</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>QA and Testing include verification of compliance with regulatory requirements and adherence to security standards. This validation is crucial, especially when handling 
                                sensitive data or building software for industries with strict regulations.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 test--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Enhancing Customer Satisfaction</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> Delivering a high-quality software product enhances customer satisfaction. QA and Testing help identify and address issues before clients or end-users encounter them,
                                 leading to a positive user experience.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 test--practice--align'>
                    <p style={{fontSize:"25px"}}>Continuous Improvement</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}> QA and Testing contribute to a culture of continuous improvement within the company. Feedback from testing results is used to enhance development processes, optimize testing strategies, 
                        and improve overall software quality.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 test--key--style'>
                    <div className='test--practice--align mt-5 '>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of QA & Testing</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 test--practice--align'>
                                <p style={{fontSize:"18px"}}>Client Requirement Validation</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 test--practice--align'>
                                <p style={{fontSize:"18px"}}>Test Automation</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 test--practice--align'>
                                <p style={{fontSize:"18px"}}>Defect Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 test--practice--align'>
                                <p style={{fontSize:"18px"}}>Compliance Testing</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}