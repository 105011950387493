import Navbar from './Navbar';
import Footer from './Footer';

export default function ITOM(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7 text-center itom--content--image title--edit p-3'>IT Operations Management</h1>
                </div>
                <h5 className='col-sm-10 mt-4 itom--content--text title--edit p-3'>To ensure the efficient and reliable delivery of IT services by managing the day-to-day operational activities and infrastructure of the IT environment. 
                ITOM encompasses a range of practices, processes, and tools that help monitor, manage, and optimize IT systems, applications, and services.</h5>
                <div className='itom--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p className='itom--mobile'>IT Operations Management(ITOM)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itom--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>System Monitoring and Alerting</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>ITOM involves continuous monitoring of IT systems, applications, and infrastructure to proactively detect issues, anomalies, and performance bottlenecks. It 
                                triggers alerts to IT teams for timely action.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itom--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Automation and Orchestration</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> ITOM utilizes automation and orchestration to streamline routine IT tasks, reducing manual efforts and minimizing the risk of human errors.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itom--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Event Correlation and Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>ITOM correlates and manages IT events to identify patterns and prioritize responses to critical incidents.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itom--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>IT Infrastructure Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> ITOM manages the physical and virtual IT infrastructure, including servers, networks, storage, and cloud resources.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 itom--practice--align'>
                    <p style={{fontSize:"25px"}}>IT Asset Management</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}>ITOM helps track and manage IT assets throughout their lifecycle, ensuring proper utilization and cost optimization.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 itom--key--style'>
                    <div className='itom--practice--align mt-5'>
                        <p style={{fontSize:"25px"}}>Key Capabilities Of ITOM</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itom--practice--align'>
                                <p style={{fontSize:"18px"}}>Metric Intelligence</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itom--practice--align'>
                                <p style={{fontSize:"18px"}}>AIOps Event Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itom--practice--align'>
                                <p style={{fontSize:"18px"}}>Service Mapping</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itom--practice--align'>
                                <p style={{fontSize:"18px"}}>Configuration Management Database</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itom--practice--align'>
                                <p style={{fontSize:"18px"}}>Discovery</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}