import React from 'react';

export default function Mission() {
    return (
        <>
            <h1 className='col-sm-6 about--mission--content--image'>OUR MISSION</h1>
            <p className='col-sm-2 about--mission--text' style={{borderBottom: "3px solid #fff"}}></p>
            <h5 className='col-sm-6 mt-4 about--mission--text'>To be at the forefront of digital advancement, providing end-to-end IT services and consulting that empower businesses to embrace change, maximize efficiency, and achieve sustainable growth.</h5>
            <div className='about--mission--image'>
            </div>
            {/* <div>
                <button className='mission--button btn-sm'>Know more</button>
            </div> */}
        </>
    );
}