import Footer from './Footer';
import Navbar from './Navbar';
import ESM from '../images/esm.png';
import DEV from '../images/devops.png';
import ISS from '../images/security.png';
import GRC from '../images/GRC.png';
import ITAM from '../images/itam.png';
import QAT from '../images/test.png';
import ITOM from '../images/itom.png';
import SUPPORT from '../images/support.png';

export default function Careers() {
    return(
        <>
        <Navbar />
            <h1 className='col-sm-12 text-center servicetest--content--image'><span className='title--edit p-3'>Our Services</span></h1>
            <h5 className='col-sm-10 mt-4 servicetest--content--text title--edit p-3'>Our team cares about your organization and the success of your ServiceNow platform.Maximize ServiceNow platform
             value by utilizing the elements of the ServiceNow products to drive consistent employee experience, automation of process, and execution of work is a key aspect of our 
             Service Offerings.</h5>
            <div className='servicetest--background--image'>
            </div>
            {/* style={{marginLeft: "40%"}} */}
            <div className='col-sm-12 text-center mt-5 about--quotes--head'>
                <p>Services</p>
                <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
            </div>
            <div className='col-sm-12 servicetest--align'>
                <div className='col-sm-10 mt-5'>
                    <div className='row'>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12 mt-1 technology--content'  >
                                <img src={ESM} className='technology--image' />
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>Enterprise Service Management</h4>
                                <p className='p-3' style={{fontWeight: 400}}>Experience the direct advantage of cost-savings and operational innovation through Dotsito ESM. With our in-depth prowess over ServiceNow platform, you can 
                                    organize all relevant business processes and enable digitalization for internal IT systems, employees and customers.</p>
                            </div>
                        </div>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12  mt-1  technology--content'>
                                <img src={DEV} className='technology--image'/>
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>DevOps and Infra Automation</h4>
                                <p className='p-3' style={{fontWeight: 400}}>Eradicate all silos by integrating development and operations activities to elevate organizational productivity. Dotsito’ DevOps integration is based on automating 
                                infrastructure and workflows with integrated CI-CD tool chain and change automation to minimize time to market.</p>
                            </div>
                        </div>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12 mt-1 technology--content'>
                                <img src={ISS} className='technology--image'/>
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>IT Security Services</h4>
                                <p className='p-3' style={{fontWeight: 400}}>Neutralize all security threats by agile detection, mapping, ServiceNow Security Operations brings incident data from your security tools into a structured response
                                engine that uses intelligent workflows, automation, and a deep connection with IT to prioritize and resolve threats based on the impact.</p>
                            </div>
                        </div>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12 mt-1 technology--content'>
                                <img src={GRC} className='technology--image'/>
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>Governance, Risk, and Compliance (GRC)</h4>
                                <p className='p-3' style={{fontWeight: 400}}>Dotsito Technologies GRC helps integrate IT and organizational processes. It transforms inefficient processes across the enterprise into an integrated risk program through
                                continuous monitoring and automation. GRC applications deliver a real-time view of compliance and risk and improve decision making.</p>
                            </div>
                        </div>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12 mt-3 technology--content'>
                                <img src={ITAM} className='technology--image'/>
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>IT Asset Management (ITAM)</h4>
                                <p className='p-3' style={{fontWeight: 400}}>Drive full lifecycle tracking and visibility into hardware assets and software licenses with costs, processes and data on a single platform.</p>
                            </div>
                        </div>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12 mt-3 technology--content'>
                                <img src={QAT} className='technology--image'/>
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>QA and Testing</h4>
                                <p className='p-3' style={{fontWeight: 400}}>Software Quality and Testing helps Web and Mobile applications through software tools. We are proficient in Security & Vulnerability, Performance, Compatibility, API, 
                                Database & Interface. We also provide Cloud, Globalisation, Localisation, and Accessibility Testing to ensure efficient and effective test coverage.</p>
                            </div>
                        </div>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12 mt-3 technology--content'>
                                <img src={SUPPORT} className='technology--image'/>
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>ServiceNow Support</h4>
                                <p className='p-3' style={{fontWeight: 400}}>Prevent service outages by gaining visibility into IT infrastructure and applications, event correlation to reduce NOC workload, actionable operational intelligence and cloud insights.</p>
                            </div>
                        </div>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12 mt-3 technology--content'>
                                <img src={ITOM} className='technology--image'/>
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>IT Operations Management (ITOM)</h4>
                                <p className='p-3' style={{fontWeight: 400}}>Prevent service outages by gaining visibility into IT infrastructure and applications, event correlation to reduce NOC workload, actionable operational intelligence and cloud insights.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-5 text-center'>
                <h4 className='m-3 mt-3'>Solutions as a Service</h4>
                    <p className='m-3 text-center'style={{fontWeight: 550}}>"Discover innovative solutions tailored to your business needs. Get started today!".</p>
                <div className='col-sm-12 text-center'>
                    <a href="mailto:operations@dotsito.com?subject='We want to know about Dotsito!'&body='Welcome to Dotsito !'"><button className='mt-3 btn-sm content--services--button'>Connect with an Expert</button></a>
                </div>
            </div>
        <Footer />
        </>
    )
}