import Navbar from './Navbar';
import Footer from './Footer';

export default function ITAM(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7 text-center fsm--content--image title--edit p-3'>Field Service Management</h1>
                </div>
                <h5 className='col-sm-10 mt-4 fsm--content--text title--edit p-3'>To efficiently manage and optimize the deployment and activities of field service technicians who are responsible for providing on-site IT services and support to clients. 
                FSM involves a set of practices, processes, and tools to ensure timely and effective delivery of services in the field.</h5>
                <div className='fsm--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p>Field Service Managemen (FSM)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 fsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Workforce Scheduling and Dispatch</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>FSM facilitates the scheduling and dispatching of field service technicians to client locations based on their skills, availability, 
                                and proximity to the service location.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 fsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Work Order Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> FSM manages work orders for field service tasks, ensuring all necessary information and instructions are provided to technicians.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 fsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Remote Troubleshooting and Support</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>FSM enables field technicians to access remote support from the central office to resolve complex issues while on-site.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 fsm--practice--align'>
                                <p  className="sms--key-cap" style={{fontSize:"25px"}}>Health and Safety Compliance</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> FSM ensures that field service technicians follow health and safety protocols while working on-site.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 fsm--practice--align'>
                    <p className="sms--key-cap" style={{fontSize:"25px"}}>Parts and Inventory Management</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}>  FSM manages inventory and parts availability to ensure that field technicians have the necessary resources to complete service tasks on-site.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 fsm--key--style'>
                    <div className='fsm--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of Field Service Management</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 fsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Asset & Cost Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 fsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Continual Improvement Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 fsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Performance Analytics</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 fsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Inventory Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 fsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Predictive Intelligence</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}