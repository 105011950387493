import Navbar from './Navbar';
import Footer from './Footer';
import React from 'react';


export default function Learn(){
    return(
        <>
        <Navbar />
        <div className='text-center mt-5'><h2>Under Construction.....!</h2></div>
        </>
    )
}

