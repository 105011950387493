import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
// import pageimages from '../images/My project (1).png'
// import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import GroupsIcon from '@mui/icons-material/Groups';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ComputerIcon from '@mui/icons-material/Computer';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import support from '../images/support.png';
import implement from '../images/implementation.png';
import integration from '../images/integration.png';
import upgrade from '../images/upgrade.png';

export default function SMS() {
    return (
        <>
            <Navbar />
            <div className='esm--head'>
                <h1 className='col-sm-7 text-center sms--content--image title--edit p-3'>ServiceNow Managed Services</h1>
            </div>
            <h5 className='col-sm-10 mt-4 sms--content--text title--edit p-3'>To provide comprehensive, ongoing support and management of the ServiceNow platform for the organization and its clients. ServiceNow managed services ensure that 
            the platform is optimized, well-maintained, and aligned with the evolving needs of the business.</h5>
            <div className='sms--background--image'>
            </div>
            <div className='col-sm-12 mt-5 about--quotes--head'>
                <p>ServiceNow Managed Services</p>
                <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
            </div>

            <div className='col-sm-12 servicetest--align'>
                <div className='col-sm-10 mt-5'>
                    <div className='row'>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12 mt-1 technology--content'  >
                                <img src={implement} className='technology--image' />
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>Implementations</h4>
                                <p className='p-3' style={{fontWeight: 400}}>The sky’s the limit when it comes to what you can achieve with ServiceNow, which is why we work closely with clients to 
                            understand their business and implement the right ServiceNow products for their processes. Our implementation services include consulting and 
                            development strategy with our certified ServiceNow technical consultants and architects to ensure a collaborative engagement from start to finish.</p>
                            </div>
                        </div>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12  mt-1  technology--content'>
                                <img src={integration} className='technology--image'/>
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>Integrations</h4>
                                <p className='p-3' style={{fontWeight: 400}}>We work with integration providers or write custom integrations with API development and tools to help solve our clients’ 
                            business problems faster. With ServiceNow’s robust capabilities and integration kits, we can support broad integrations, automated workflows, 
                            orchestrated external systems, and continual service improvements.</p>
                            </div>
                        </div>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12 mt-1 technology--content'>
                                <img src={support} className='technology--image'/>
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>Support</h4>
                                <p className='p-3' style={{fontWeight: 400}}>A single easy-access and integrated platform to optimize your telecom operations for best customer service and simplified 
                            service delivery. We have worked on both sides of the table on the customer side and the consulting side and have unique perspectives and 
                            skill sets when it comes efficiently managing, maintaining, and innovating your ServiceNow environment.</p>
                            </div>
                        </div>
                        <div className='col-sm-3 d-flex justify-content-center'>
                            <div className='col-sm-12 mt-1 technology--content'>
                                <img src={upgrade} className='technology--image'/>
                                <h4 className='mt-3 p-3' style={{fontWeight: 'bold'}}>Upgrade</h4>
                                <p className='p-3' style={{fontWeight: 400}}>Once you have planned your upgrade, you can begin the process. The steps involved in upgrading a ServiceNow managed service 
                            will vary depending on the method you are using. With the help of servicenow managed service Back up your instance,Update your custom applications and integrations,
                            Upgrade your instance,Test your instance,Deploy your instance to production.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-sm-12 mt-5 about--quotes--head'>
                <p>Other Features</p>
                <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
            </div>

            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-sm-6 mt-5'>
                        <div className='col-sm-12 sms--practice--align'>
                            <p className="sms--key-cap" style={{fontSize:"25px"}}>Platform Stability and Performance</p>
                            <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                        </div>
                        <div>
                            <p style={{fontWeight: 400}}>ServiceNow managed services aim to maintain the stability and optimal performance of the ServiceNow platform. This includes proactive monitoring, troubleshooting, and resolving
                             any issues that may arise to minimize downtime and disruptions.</p>
                        </div>
                    </div>
                    <div className='col-sm-6 mt-5'>
                        <div className='col-sm-12 sms--practice--align'>
                            <p className="sms--key-cap" style={{fontSize:"25px"}}>Incident and Problem Resolution</p>
                            <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                        </div>
                        <div>
                            <p style={{fontWeight: 400}}>Providing timely and effective support for incident and problem resolution related to the ServiceNow platform. This ensures that any issues faced by users are addressed promptly,
                             reducing the impact on operations.</p>
                        </div>
                    </div>
                    <div className='col-sm-6 mt-5'>
                        <div className='col-sm-12 sms--practice--align'>
                            <p style={{fontSize:"25px"}}>Continuous Improvement</p>
                            <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                        </div>
                        <div>
                            <p style={{fontWeight: 400}}> ServiceNow managed service providers focus on continuous improvement to enhance the platform's capabilities and efficiency. They identify opportunities to optimize processes, 
                            automate workflows, and improve user experience.</p>
                        </div>
                    </div>
                    <div className='col-sm-6 mt-5'>
                        <div className='col-sm-12 sms--practice--align'>
                            <p style={{fontSize:"25px"}}>Cost Optimization</p>
                            <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                        </div>
                        <div>
                            <p style={{fontWeight: 400}}>ServiceNow managed services help optimize the total cost of ownership for the platform by ensuring efficient resource utilization and minimizing unnecessary expenses.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-5 sms--practice--align'>
                <p style={{fontSize:"25px"}}>Security and Compliance</p>
                <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                <div className='col-sm-10'>
                    <p style={{fontWeight: 400}}> Ensuring that the ServiceNow platform is configured securely and compliant with relevant industry standards and regulations. This involves managing access control, data privacy, 
                    and security settings.</p>
                </div>
            </div>
            <div className='col-sm-12 p-3 mt-5 sms--key--style'>
                <div className='sms--practice--align mt-5'>
                    <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of ServiceNow Managed Services</p>
                    <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='row'>
                    <div className='col-sm-6 mt-5'>
                        <div className='col-sm-8 sms--practice--align'>
                            <p style={{fontSize:"18px"}}>Flexibility</p>
                            <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                        </div>
                    </div>
                    <div className='col-sm-6 mt-5'>
                        <div className='col-sm-6 sms--practice--align'>
                            <p style={{fontSize:"18px"}}>Value-Driven Approach</p>
                            <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                        </div>
                    </div>
                    <div className='col-sm-6 mt-5'>
                        <div className='col-sm-8 sms--practice--align'>
                            <p className="sms--key-cap" style={{fontSize:"18px"}}>Predictable And Transparent Collaboration</p>
                            <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                        </div>
                    </div>
                    <div className='col-sm-6 mt-5'>
                        <div className='col-sm-6 sms--practice--align'>
                            <p style={{fontSize:"18px"}}>Development And Evolution</p>
                            <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                        </div>
                    </div>
                    <div className='p-3'></div>
                </div>
            </div>
            <Footer />
        </>
    )
};