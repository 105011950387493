import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import IntroDesign from './IntroDesign';
import pageimages from '../images/My project (4).png';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function CustomerWorkflows(){
    return (
        <>
            <Navbar />
            <div className='text--product'>
                <span className='m-3 card--heading'>CUSTOMER WORKFLOWS</span>
            </div>
            <IntroDesign />
                <div className='container mt-5 text-center'>
                    <p className='m-3 text-center'style={{fontWeight: 550}}>In order to keep your customers your top priority, we bring you digital workflows that automate all your customer centric tasks And this skyrockets your customer experience</p>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-sm-4 d-flex justify-content-center card--body'>
                            <div className='col-sm-12 m-5 itworkflow--content'>
                                <h4 className='m-5 mt-5'>Customer Service Management</h4>
                                <p className='m-4 mt-5'>Enhance the customer satisfaction by leveraging AI driven self service support and fixing issues proactively with predictive intelligence</p>
                                <h5 className='m-5 mt-5'>Key Capabilities</h5>
                                <div className='m-5 mt-5'>
                                    <ArrowRightIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}>Case Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Knowledge Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Virtual Agent Chatbot</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Communities</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Performance Analytics</span><br/>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4  d-flex justify-content-center'>
                            <div className='col-sm-12 m-5 itworkflow--content'>
                                <h4 className='m-5 mt-5'>Field Service Management (FSM)</h4>
                                <p className='m-5 mt-5'>Adopt modernized FSM tools and increase the first time fix-in rate by empowering field teams with mobility and ability to connect to other teams</p>
                                <h5 className='m-5 mt-5'>Key Capabilities</h5>
                                <div className='m-5 mt-5'>
                                    <ArrowRightIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}>Asset & Cost Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Continual Improvement Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Performance Analytics</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Virtual Agent</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Predictive Intelligence</span><br/>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4  d-flex justify-content-center'>
                            <div className='col-sm-12 m-5 itworkflow--content'>
                                <h4 className='m-5 mt-5'> Telecommunications Service Management </h4>
                                <p className='m-5 mt-5'>A single easy-access and integrated platform to optimize your telecom operations for best customer service and simplified service delivery </p>
                                <h5 className='m-5 mt-5'>Key Capabilities</h5>
                                <div className='m-5 mt-5'>
                                    <ArrowRightIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}>Telecommunications Assurance Workflows</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Case Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Advanced Work Assignment</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Order Management for Telecommunications</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Customer Central</span><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-6 mt-5 text-center about--image--animation'>
                    <img src={pageimages} id='animation' className='about--image' alt="network" width="60%" height="100%" />
                    </div>
                    <div className='col-sm-6 mt-5 about--text--animation'>
                        <h4 className='text-center'> In-Depth Capability and Proficiency </h4>
                        <p className='mt-5'style={{fontWeight: 550}}>Employ our ServiceNow consulting services approach with Digital Workflows to deliver seamless technology at every touch point in your enterprise.</p><br/>
                        <DoubleArrowIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}> One stop-shop for all end users – A self-service portal for all service requests, and incident reporting for various business departments</span><br/>
                        <br/>
                        <DoubleArrowIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}> Seamless tracking of status requests and incidents, and knowledge-base for quick workaround and self-help</span><br/>
                        <br/>
                        <DoubleArrowIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}> Workflow automation with zero manual-updating procedures, and advanced work assignments to right agent</span><br/>
                        <br/>
                        <DoubleArrowIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}> Tracking of KPIs related to SLA compliance, request volume, approval status, and associated financial costs</span><br/>
                        <br/>
                        <DoubleArrowIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}> Reduction in MTTR of incidents and increase in self-service index</span><br/>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    ) 
}