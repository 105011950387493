import React from 'react';
import expertise from './expertise-2.png';
import focus from './focus-1.png';
import innovation from './innovation-1.png';
import integrity from './integrity-1.png';
import agility from './agility-1.png';

export default function CoreValues() {
    return (
        <>
            <div className='container-fluid core--base mt-5'>
                <div className='col-sm-10 core--inner p-4'>
                    <div className='row'>
                        <div className='col-sm-6 core--image--content'>
                            <img src={expertise} width={700} height={250} style={{borderRadius: "5px"}} alt="" />
                        </div>
                        <div className='col-sm-6 core--align'>
                            <p style={{color: "#fff",fontSize:"25px"}}>Expertise</p>
                            <p style={{fontWeight: 400,fontSize:"18px"}}>  Our team comprises top-tier professionals with a diverse range of skills and knowledge.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid core--base'>
                <div className='col-sm-10 core--inner1  mt-5 p-4'>
                    <div className='row core--mobile'>
                        <div className='col-sm-6 core--align'>
                            <p style={{color: "#fff",fontSize:"25px"}}>Focus</p>
                            <p style={{fontWeight: 400,fontSize:"18px"}}> Our clients are at the heart of everything we do. We listen, understand, and prioritize their needs to deliver 
                            tailored solutions that exceed expectations.</p>
                        </div>
                        <div className='col-sm-6 core--image--content flex-row-reverse'>
                            <img src={focus} width={700} height={250} style={{borderRadius: "5px"}} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid core--base'>
                <div className='col-sm-10 core--inner mt-5 p-4'>
                    <div className='row'>
                        <div className='col-sm-6 core--image--content'>
                            <img src={innovation} width={700} height={250} style={{borderRadius: "5px"}} alt="" />
                        </div>
                        <div className='col-sm-6 core--align'>
                            <p style={{color: "#fff",fontSize:"25px"}}>Innovation</p>
                            <p style={{fontWeight: 400,fontSize:"18px"}}>  Embracing change and staying ahead of the curve is in our DNA. We continuously explore new technologies and industry trends to 
                            offer innovative solutions that give our clients a competitive edge.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid core--base'>
                <div className='col-sm-10 core--inner1 mt-5 p-4'>
                    <div className='row core--mobile'>
                        <div className='col-sm-6 core--align'>
                            <p style={{color: "#fff",fontSize:"25px"}}>Agility</p>
                            <p style={{fontWeight: 400,fontSize:"18px"}}> In the fast-paced world of technology, adaptability is crucial. We pride ourselves on our ability to be agile and responsive to the ever-changing demands of the industry.</p>
                        </div>
                        <div className='col-sm-6 core--image--content'>
                            <img src={agility} width={700} height={250} style={{borderRadius: "5px"}} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid core--base'>
                <div className='col-sm-10 core--inner mt-5 p-4'>
                    <div className='row'>
                        <div className='col-sm-6 core--image--content'>
                            <img src={integrity} width={700} height={250} style={{borderRadius: "5px"}} alt="" />
                        </div>
                        <div className='col-sm-6 core--align'>
                            <p style={{color: "#fff",fontSize:"25px"}}>Integrity</p>
                            <p style={{fontWeight: 400,fontSize:"18px"}}> Honesty and transparency form the foundation of our interactions with clients, partners, and team members. We believe in building trust through ethical business practices.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}