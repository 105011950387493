import Navbar from './Navbar';
import Footer from './Footer';

export default function Devops(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7 text-center devops--content--image title--edit p-3'>Devops and Infra Automation </h1>
                </div>
                <h5 className='col-sm-10 mt-4 devops--content--text title--edit p-3'>Devops and Infra automation to enhance the efficiency, reliability, and scalability of their software development and IT operations. 
                These practices are essential for modern organizations that want to deliver high-quality products and services at a faster pace while maintaining a competitive edge.</h5>
                <div className='devops--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p>Devops Practice</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 devops--practice--align'>
                                <p style={{fontSize:"25px"}}>Automate Everything</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Automate repetitive and manual tasks as much as possible. This includes building, testing, and deploying software, 
                                infrastructure provisioning, and configuration management. Automation reduces errors, increases consistency, and accelerates delivery.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 devops--practice--align'>
                                <p style={{fontSize:"25px"}}>(CI/CD)</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Implement CI/CD pipelines to automate the build, testing, and deployment of code changes. This ensures that code 
                                is continuously integrated, tested, and delivered to production environments in a controlled and efficient manner.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 devops--practice--align'>
                                <p style={{fontSize:"25px"}}>Infrastructure as Code (IaC)</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> Use Infrastructure as Code principles to manage and provision infrastructure through code. This approach ensures consistency,
                                 version control, and allows for easy replication of environments.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 devops--practice--align'>
                                <p style={{fontSize:"25px"}}>Monitoring and Logging</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Implement comprehensive monitoring and logging systems to gain insights into the application and infrastructure performance. 
                                Monitoring helps detect issues early, and logging provides valuable information for debugging and auditing.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 devops--practice--align'>
                    <p style={{fontSize:"25px"}}>Test Automation</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}> Invest in test automation to ensure rapid and reliable testing of applications. Automated tests help identify regressions early and provide 
                        confidence in the software's stability.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 devops--key--style'>
                    <div className='devops--practice--align mt-5'>
                        <p style={{fontSize:"25px"}}>Key Capabilities Of Devops</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 devops--practice--align'>
                                <p style={{fontSize:"18px"}}>Enterprise DevOps</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 devops--practice--align'>
                                <p style={{fontSize:"18px"}}>DevOps Change Automation</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 devops--practice--align'>
                                <p style={{fontSize:"18px"}}>DevOps Agile Team Planning</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 devops--practice--align'>
                                <p style={{fontSize:"18px"}}>DevOps Change Acceleration</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 devops--practice--align'>
                                <p style={{fontSize:"18px"}}>Integration Hub</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 devops--practice--align'>
                                <p style={{fontSize:"18px"}}>Lower rate of change failures</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}