// import React from 'react';
import React, { useState } from 'react';
import Navbar from './Navbar';
import career_images from '../images/careers.png';
import Footer from './Footer';
import quotes from '../images/quotes.png';
import careerData from '../components/careerData';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import $ from "jquery";

import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ConstructionOutlined } from '@mui/icons-material';

// import firebase from "firebase";
// import { getDatabase, ref, push } from 'firebase/firebase-database';

// import { useRef } from 'react';
// import emailjs from '@emailjs/browser';

const ScrollingContentWithBackground = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

   
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState(careerData);
    const [searchLoc, setSearchLoc] = useState('');
    
    const [name, setName] = useState('');
    const [lname,setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [exp, setExp] = useState('');
    const [city, setCity] = useState('');
    const [role, setRole] = useState('');
    // const [rsfile,setFile] = useState('');
    // const[formData,setFormData] = useState({
    //     name : '',
    //     email: '',
    //     file : '',
    // })

    
    const options = ['0-2 yrs', '2-5 yrs', '5-10 yrs', '10+ yrs'];
    const roles = ["Devops Engineer","Python Developer","ServiceNow Architect","Tools Architect","Java Developer","Cloud Architect"]


    // Create a new node and store form data

    // if (file) {
    //   const storageRef = ref(storage, `YOUR_STORAGE_REFERENCE/filename`);
    //   uploadBytes(storageRef, file);
    //   const downloadURL = getDownloadURL(storageRef);

 
    //   update(newFormRef, { file: downloadURL });
    // }
    // const formRef = useRef();


    // const sendEmail = () => {
        
    
    //     const emailjsOptions = {
    //       serviceId: 'service_52eiriw',
    //       templateId: 'template_9zcuhzg',
    //       to: "timepassforthinking@gmail.com",
    //       from: 'timepassforthinking@gmail.com',
    //       subject: 'Resume',
    //       text: 'Resume',
    //       variables: {
    //         name: name,
    //         email: email,
    //         phone: phone
    //       },
    //     };
    //     console.log(emailjsOptions);
    //     emailjs.send(emailjsOptions);
    //   };

    // const sendEmail = (e) => {
    //     e.preventDefault();

    //     emailjs.sendForm('service_52eiriw', 'template_lmy27gf', form.current, 'KoIzmoUhOlevuNVRO')
    //     .then((result) => {
    //         console.log(result.text);
    //         console.log("message sent");
    //         setName(' ');
    //         setLastName(' ');
    //         setEmail(' ');
    //         setPhone(' ');
    //         setExp(' ');
    //         setCity(' ');
    //         setRole(' ');
    //         setFile(' ');

    //     }, (error) => {
    //         console.log(error.text);
    //     });
    // };

    // const handleChange = (event) => {
    //     const { name, value } = event.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    // const sendEmail = async (e) => {
    //     e.preventDefault();

        // console.log(name,email,phone,city,exp,role,rsfile);

        // const blob = new FormData();
        // blob.append('name', formData.name);
        // blob.append('email', formData.email);
        // blob.append('file', formData.file);
        // formData.append('phoneno', phone);
        // formData.append('Experience', exp);
        // formData.append('city', phone);
        // formData.append('phoneno', phone);
        // console.log(blob['name']);

        // try {

        //     const response = await fetch('https://dotsito.com/api/submitForm.php', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: blob,
        //     });

            // Handle the response from the server
            // const data = await response.json();
            // console.log(data);

            // } catch (error) {
            //     console.error('Error uploading form data:', error);
            // }
            // Send form data to the PHP backend
        //     const response = await axios.post('https://dotsito.com/api/submitForm.php', formData);
        //     console.log(response);
            
        //     if (response.status === 200) {
        //       console.log('Form data submitted successfully!');
        //     } else {
        //       console.error('Error submitting form data');
        //     }
        //   } catch (error) {
        //     console.error('Error submitting form data:', error);
        //   }
    // };


    //     const firebaseConfig = {
    //         apiKey: "AIzaSyCzXbaVNBjMrwNMIsaaMscs4i6dKjQYXAU",
    //         authDomain: "test-formdata-a6c87.firebaseapp.com",
    //         projectId: "test-formdata-a6c87",
    //         storageBucket: "test-formdata-a6c87.appspot.com",
    //         messagingSenderId: "886813649240",
    //         appId: "1:886813649240:web:60f459571ffdc05cb8dd2d",
    //         measurementId: "G-FZKRTJX1FC"
    //     };

    //     const app = firebase.initializeApp(firebaseConfig);
    //     const db = getDatabase();

    //     const newFormRef = push(ref(db, 'forms'), {
    //         name,
    //         email,
    //         phone,
    //       });

    //     console.log('Form data stored in Firebase');
    // }

    // database.ref("User").set({
    //     name: name,
    //     email: email,
    //     phone: phone,
    //     file: rsfile
    // }).catch(alert);


    // const handleSubmit = (e) => {
    //     e.preventDefault();
    

    //     const form = $(e.target);
    //     $.ajax({
    //         type: "POST",
    //         url: "https://dotsito.com/public_html/Php/mysql.php",
    //         data: form.serialize(),
    //         success(data) {
    //             alert(data);
    //         },
    //     });
    // };
    //     const data = {
    //       name: name,
    //       email: email,
    //       phoneno: phone,
    //       exp: exp,
    //       city: city,
    //       role: role,
    //       file: rsfile
    //     };
    
    //     axios.post('https://dotsito.com/mysql.php', data)
    //       .then((res) => {
    //         console.log(res);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   };

    
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault(); 
        emailjs.sendForm('service_s63pkce', 'template_lmy27gf', form.current, 'KoIzmoUhOlevuNVRO')
        .then((result) => {
            console.log(result.text);
            console.log("message sent");
            setName(' ');
            setLastName(' ');
            setEmail(' ');
            setPhone(' ');
            setExp(' ');
            setRole(' ');
            setCity(' ');

        }, (error) => {
            console.log(error.text);
        });
    };

    // const onOptionChangeHandler = (event) => {
    //     console.log("User Selected Value - ", event.target.value)
    //     setExp(event.target.value)
    // }

    // const onOptionRoleHandler = (event) => {
    //     console.log("User Selected Value - ", event.target.value)
    //     setRole(event.target.value)
    // }

   

    const filteredData = data.career.filter(item =>
        {
        return (item.name.toLowerCase().includes(searchQuery.toLowerCase())) && (item.location.toLowerCase().includes(searchLoc.toLowerCase()));
        }
    );


    // const linkName = readMore ? 'Read Less << ':'Read More >> '

  return (
    <>
    <Navbar />
    <h1 className='col-sm-10 career--content--image title--edit p-3'><span>We treat every team member as part of one family. Dotsito Family!</span></h1>
    <h5 className='col-sm-4 career--content--text title--edit p-3'>Dare to dream big. Our company is where aspirations turn into reality.</h5>
    <div className='career--background--image'>
    </div>
    <div className='col-sm-12 careeer'>
        <div className='col-sm-12 career--edit--background--image '>
        </div>
    </div>
    <div className='col-sm-12 careeer'>
        <h5 className='col-sm-9 career--edit--content--text'>Your work environment impacts your mood, drive, mental health and performance.
        That’s why we creating a positive work environment for you and your company’s success.</h5>
    </div>
    <div className='col-sm-12 text-center career--edit--image--text'>
      <img src={career_images} width={570} />
    </div>
    <br/><br/>
    <br/><br/>
    <br/><br/>
    <br/><br/>

    <div className='mt-5'>
        &nbsp;
    </div>
    
    <div className='col-sm-12 text-center mt-5 about--quotes--head'>
        <p>Job Openings</p>
        <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
    </div>

    <div className='col-sm-12 mt-5'>
        <div className='row'>
            <div className='col-sm-3 text-center'>
                <label>Search &nbsp;&nbsp;&nbsp;:&nbsp;</label>
                <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                style={{ width: '300px', padding: '10px' }}
                onChange={e => setSearchQuery(e.target.value)}
                />
                <br/>
                <br/>
                <label>Location :&nbsp;</label>
                <input
                type="text"
                placeholder="Location..."
                value={searchLoc}
                style={{ width: '300px', padding: '10px' }}
                onChange={e => setSearchLoc(e.target.value)}
                />
            </div>
            
            <div className='col-sm-9'>
                <div className='col-sm-9 career--heading'>
                    <p>Position</p>
                    <p>Job Id</p>
                    <p>Location</p>
                    <p>Posted</p>
                </div>
                {filteredData.map(item =>(
                    <>
                    <div className='col-sm-10 p-4 career-card--design'>
                            <div className='col-sm-12'>
                                <   div className='career--job--head'>
                                    <p className='mt-2' style={{fontWeight: 'bold'}}>{item.name}</p>
                                    <p className='mt-2 '>{item.id}</p>
                                    <p className='mt-2 p-2'>{item.location}</p>
                                    <p className='mt-2 p-2'>{item.date}</p>
                                </div>
                                <p className='mt-4'>Job Description:</p>
                                <p className='mt-2' style={{fontWeight: 400}}>{item.desc}</p>
                                <p className='mt-4'>Responsibilities:</p>
                                <p className='' style={{fontWeight: 400}}>{item.step1}</p>
                                <p className='' style={{fontWeight: 400}}>{item.step2}</p>
                                <p className='' style={{fontWeight: 400}}>{item.step3}</p>
                                <p className='' style={{fontWeight: 400}}>{item.step4}</p>
                                <p className='' style={{fontWeight: 400}}>{item.step5}</p>
                                <p>Qualifications:</p>
                                <p className='' style={{fontWeight: 400}}>{item.qualify}</p>
                                <p><button className='"btn-modal" career--button btn-sm' onClick={handleOpen}>{item.apply}</button></p>
                                <p className='career--design'></p>
                            </div>
                        </div>
                        <p className='career--card--bottom'></p> 
                    </>
                ))}
            </div>
        </div>
    </div>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <div className='container mt-5'>
                <form className='career--form bg-light' ref={form} onSubmit={sendEmail} enctype="multipart/form-data" > 
                    <div className='col-sm-12'> 
                        <div className='row'>
                            <div className="col-sm-6">
                                <label for="exampleInputEmail1" className="form-label">FirstName</label>
                                <input type="text"  name="name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" required/>
                            </div>
                            <div className="col-sm-6">
                                <label for="exampleInputPassword1" className="form-label">Lastname</label>
                                <input type="text" name="lname" value={lname} onChange={(e) => setLastName(e.target.value)} className="form-control" id="exampleInputPassword1" />
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 col-sm-12'>
                        <div className='row'>
                            <div className="col-sm-6">
                                <label for="exampleInputEmail1" className="form-label">Email</label>
                                <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" required />
                            </div>
                            <div className="col-sm-6">
                                <label for="exampleInputPassword1" className="form-label">Phone</label>
                                <input type="text" value={phone} name="phoneno" onChange={(e) => setPhone(e.target.value)} className="form-control" id="exampleInputPassword1" />
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 col-sm-12'>
                        <div className='row'>
                            <div className="col-sm-6">
                                <label for="exampleInputEmail1" className="form-label">Experience</label>
                                <select className="form-control" value={exp} onChange={(e) => setExp(e.target.value)} name="exp">
                                    <option>Please choose one option</option>
                                    {options.map((option, index) => {
                                        return <option key={index} >
                                            {option}
                                        </option>
                                    })}
                                </select>
                            </div>
                            <div className="col-sm-6">
                                <label for="exampleInputPassword1" className="form-label">City</label>
                                <input type="text" name="city" value={city} onChange={(e) => setCity(e.target.value)} className="form-control" id="exampleInputPassword1" />
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 col-sm-12">
                        <label for="exampleInputEmail1" className="form-label">Role</label>
                        <select className="form-control" value={role} onChange={(e) => setRole(e.target.value)} name="role">
                            <option>Please choose one option<KeyboardArrowDownIcon /></option>
                            {roles.map((option, index) => {
                                return <option key={index} >
                                    {option}
                                </option>
                            })}
                        </select>
                    </div>
                    {/* <div className="mt-3 col-sm-12">
                        <label for="exampleInputEmail1" className="form-label">Comments</label>
                        <textarea type="file" value={text} onChange={(e) => setArea(e.target.value)} className="form-control" />
                    </div> */}
                    <div className="mt-3 col-sm-12">
                        <p>Please email your detailed resume to<span style={{color:"blue"}}>&nbsp;operation@dotsito.com.&nbsp;</span>We will be in touch soon!</p>
                    </div>
                    <div className='mt-3 col-sm-12 text-center'>
                        <button className='mt-3 btn-sm content--button' type="submit">Submit</button>
                    </div>
                    <br />
                </form>
            </div>
        </Box>
      </Modal>

    <div className='container p-3 mt-5 text-center career--quotes'>
        <img src={quotes} width={45} />
        <p style={{fontWeight: 500}}>Innovate. Solve. Repeat.</p>
    </div>
    <Footer />
    </>
    
  );
};


export default ScrollingContentWithBackground;

