import React from 'react';

export default function Vision() {
    return (
        <>
            <h1 className='col-sm-6 about--vision--content--image'>OUR VISION</h1>
            <p className='col-sm-2 about--vision--text show' style={{borderBottom: "3px solid #fff"}}></p>
            <h5 className='col-sm-6 mt-4 about--vision--text'>Envisioning a world where technology empowers organizations to thrive, adapt, and lead with sustainable innovation.</h5>
            <div className='about--vision--image'>
            </div>
            {/* <div>
                <button className='vision--button btn-sm'>Know more</button>
            </div> */}
        </>
    );
}