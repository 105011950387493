import Navbar from './Navbar';
import Footer from './Footer';

export default function ITAM(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-9 text-center tsm--content--image title--edit p-2'>Telecommunications Service Management</h1>
                </div>
                <h5 className='col-sm-10 mt-4 tsm--content--text title--edit p-3'>Telecommunications services to the organization and its clients. Telecommunications Service Management involves a set of practices, processes, 
                and tools to ensure reliable and effective communication infrastructure and services.</h5>
                <div className='tsm--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p className='tsm--content--head--mobile'> Telecommunications Service Management (TSM)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Telecommunications Infrastructure Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> Telecommunications Service Management oversees the design, implementation, and maintenance of the organization's 
                                telecommunications infrastructure, including networks, servers, and communication devices.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Data Communication Services</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Telecommunications Service Management manages data communication services, such as internet connectivity and networking, 
                                to facilitate efficient data transfer and collaboration.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Mobile Device Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Telecommunications Service Management handles the management and security of mobile devices used within the organization, 
                                ensuring data protection and compliance.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Change and Configuration Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> Telecommunications Service Management manages changes to the telecommunications infrastructure in a controlled and documented manner.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 tsm--practice--align'>
                    <p className="sms--key-cap" style={{fontSize:"25px"}}>Telecommunications Security</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}> Telecommunications Service Management implements security measures to protect telecommunications infrastructure and data from potential threats.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 tsm--key--style'>
                    <div className='tsm--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of Telecommunications Service Management</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"18px"}}>Telecommunications Assurance Workflows</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Service Bridge</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Advanced Work Assignment</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Flow Designer</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Customer Central</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 tsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Communities</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}