import Navbar from './Navbar';
import Footer from './Footer';

export default function ITAM(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7 text-center lsd--content--image title--edit p-3'>Legal Service Delivery</h1>
                </div>
                <h5 className='col-sm-10 mt-4 lsd--content--text title--edit p-3'>ServiceNow can be customized to cater to the legal needs of IT service 
                providers and consulting firms, helping them manage legal matters, contracts, compliance, and other legal aspects efficiently.</h5>
                <div className='lsd--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p>Legal Service Delivery</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 lsd--practice--align'>
                                <p style={{fontSize:"25px"}}>Contract Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> ServiceNow can be configured to manage IT contracts, including client contracts, vendor agreements, and service-level agreements (SLAs). 
                                Legal teams can track contract statuses, renewal dates, and monitor compliance with contractual obligations.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 lsd--practice--align'>
                                <p style={{fontSize:"25px"}}>Compliance Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>IT service and consulting companies often have specific compliance requirements, such as data protection regulations, 
                                IT security standards, and industry-specific regulations. ServiceNow can be configured to manage compliance tasks, audits, and documentation related to IT service delivery.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 lsd--practice--align'>
                                <p style={{fontSize:"25px"}}>Legal Request Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>ServiceNow allows IT service companies to create standardized request forms for legal services, contract reviews, and legal advice. Legal teams can efficiently
                                 handle and prioritize legal requests using automated workflows.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 lsd--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Legal Document Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> Legal Service Delivery in ServiceNow enables the secure storage and retrieval of legal documents related to IT contracts, agreements, 
                                and compliance records.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 lsd--practice--align'>
                    <p style={{fontSize:"25px"}}>Auditing and Security</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}>ServiceNow offers built-in auditing features and access controls to ensure data security and compliance with legal requirements for 
                        sensitive legal information related to IT services.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 lsd--key--style'>
                    <div className='lsd--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of Legal Service Delivery</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 lsd--practice--align'>
                                <p style={{fontSize:"18px"}}>Legal Investigations</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 lsd--practice--align'>
                                <p style={{fontSize:"18px"}}>Legal Request Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 lsd--practice--align'>
                                <p style={{fontSize:"18px"}}>Legal Counsel Center</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 lsd--practice--align'>
                                <p style={{fontSize:"18px"}}>Reporting</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 lsd--practice--align'>
                                <p style={{fontSize:"18px"}}>Employee Center</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}