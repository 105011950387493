import Navbar from './Navbar';
import Footer from './Footer';

export default function Support(){
    return(
        <>
            <Navbar />
                <h1 className='col-sm-12 text-center support--content--image'>ServiceNow Support Service</h1>
                <h5 className='col-sm-10 mt-4 support--content--text'>To provide efficient and effective management of IT service operations and processes using the ServiceNow platform. 
                ServiceNow is a powerful IT Service Management (ITSM) tool that 
                offers a range of capabilities to streamline IT operations, enhance service delivery, and improve overall IT service quality.</h5>
                <div className='support--background--image'>
                </div>
                <div className='col-sm-12 text-center mt-5 about--quotes--head'>
                    <p>ServiceNow Support <span style={{color: "#1D61E7"}}>Service</span></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 support--practice--align'>
                                <p style={{fontSize:"25px"}}>Incident Management</p>
                                <p className='col-sm-3' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>ServiceNow support enables efficient handling and resolution of IT incidents, minimizing downtime, and reducing the impact of 
                                disruptions on business operations.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 support--practice--align'>
                                <p style={{fontSize:"25px"}}>Problem Management</p>
                                <p className='col-sm-3' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> The platform assists in identifying and addressing the root causes of recurring incidents, helping to prevent them from happening again in the future.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 support--practice--align'>
                                <p style={{fontSize:"25px"}}>Asset Management   </p>
                                <p className='col-sm-3' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> ServiceNow enables comprehensive management of IT assets, tracking hardware, software, licenses, and other IT resources throughout their lifecycle.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 support--practice--align'>
                                <p style={{fontSize:"25px"}}>Automation and Workflows</p>
                                <p className='col-sm-3' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>ServiceNow support enables the automation of routine IT tasks and workflows, reducing manual efforts and improving overall operational efficiency.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 support--practice--align'>
                    <p style={{fontSize:"25px"}}>Integration with IT Tools</p>
                    <p className='col-sm-2' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}>ServiceNow supports integration with various IT tools and systems, providing a unified view of IT operations and enabling better collaboration between 
                        different teams.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 support--key--style'>
                    <div className='support--practice--align mt-5'>
                        <p style={{fontSize:"25px"}}>Key Capabilities Of ServiceNow Support</p>
                        <p className='col-sm-1' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 support--practice--align'>
                                <p style={{fontSize:"18px"}}>Automated Workflows</p>
                                <p className='col-sm-1' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 support--practice--align'>
                                <p style={{fontSize:"18px"}}>Continuous Improvement</p>
                                <p className='col-sm-1' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 support--practice--align'>
                                <p style={{fontSize:"18px"}}>Security and Access Control</p>
                                <p className='col-sm-1' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 support--practice--align'>
                                <p style={{fontSize:"18px"}}>Mobile Support</p>
                                <p className='col-sm-1' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}