import Navbar from './Navbar';
import Footer from './Footer';

export default function ITService(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7 text-center itsm--content--image title--edit p-3'>IT Service Management</h1>
                </div>
                <h5 className='col-sm-10 mt-4 itsm--content--text title--edit p-3'>To establish a set of best practices, processes, and tools to effectively plan, design, deliver, and support IT services for the organization and its clients. 
                ITSM aims to align IT services with business objectives, improve service quality, and enhance customer satisfaction.</h5>
                <div className='itsm--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p>IT Service Management (ITSM)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Service Level Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> ITSM establishes and manages service level agreements (SLAs) with clients, defining the agreed-upon levels of service quality and performance.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Configuration Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>ITSM includes configuration management processes to maintain accurate and up-to-date information about IT assets and configurations.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Change Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>ITSM ensures that changes to IT services and systems are managed and implemented in a controlled manner, minimizing risks and disruptions.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itsm--practice--align'>
                                <p style={{fontSize:"25px"}}>Problem Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>  ITSM addresses the root causes of recurring incidents and problems to prevent them from happening in the future.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 itsm--practice--align'>
                    <p style={{fontSize:"25px"}}>Incident Management</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}>The ITSM framework includes incident management processes to quickly and efficiently restore normal service operations following any service disruptions or incidents.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 itsm--key--style'>
                    <div className='itsm--practice--align mt-5'>
                        <p style={{fontSize:"25px"}}>Key Capabilities Of ITSM</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Incident management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Now Mobile app</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Change and release management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itsm--practice--align'>
                                <p style={{fontSize:"18px"}}>CMDB</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itsm--practice--align'>
                                <p style={{fontSize:"18px"}}>Virtual Agent Chatbot</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}