import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import GroupsIcon from '@mui/icons-material/Groups';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ComputerIcon from '@mui/icons-material/Computer';

export default function Platform(){
    return (
        <>
            <Navbar />
            <h1 className='col-sm-12 text-center platform--content--image'>Exceptional Solutions On The ServiceNow Platform</h1>
            <h5 className='col-sm-10 mt-4 platform--content--text'>ESM aims to improve efficiency, collaboration, and customer satisfaction by standardizing and automating service delivery 
                processes throughout the organization.</h5>
            <div className='platform--background--image'>
            </div>
            <div className='col-sm-12 text-center mt-5 about--quotes--head'>
                <p>ServiceNow <span style={{color: "#1D61E7"}}>Platform</span></p>
            </div>
            <div className='container mt-3'>
                <div className='row'>
                    <div className='col-sm-4 d-flex justify-content-center'>
                        <div className='col-sm-10 m-5 service--content'>
                            <div className='ml-4 m-3 '>
                                <ComputerIcon style={{fontSize : "45px"}} />
                            </div>
                            <h4 className='m-3 mt-4'>Implementations</h4>
                            <p className='m-4 mt-5'>The sky’s the limit when it comes to what you can achieve with ServiceNow, which is why we work closely with clients to 
                            understand their business and implement the right ServiceNow products for their processes.<br/> Our implementation services include consulting and 
                            development strategy with our certified ServiceNow technical consultants and architects to ensure a collaborative engagement from start to finish.</p>
                        </div>
                    </div>
                    <div className='col-sm-4 d-flex justify-content-center'>
                        <div className='col-sm-10 m-5 service--content'>
                            <div className='ml-4 m-3 '>
                                <IntegrationInstructionsIcon style={{fontSize : "45px"}} />
                            </div>
                            <h4 className='m-3 mt-4'>Integrations</h4>
                            <p className='m-4 mt-5'>We work with integration providers or write custom integrations with API development and tools to help solve our clients’ 
                            business problems faster.<br/>With ServiceNow’s robust capabilities and integration kits, we can support broad integrations, automated workflows, 
                            orchestrated external systems, and continual service improvements.</p>
                        </div>
                    </div>
                    <div className='col-sm-4 d-flex justify-content-center'>
                        <div className='col-sm-10 m-5 service--content'>
                            <div className='ml-4 m-3 '>
                                <GroupsIcon style={{fontSize : "45px"}} />
                            </div>
                            <h4 className='m-3 mt-4'> Managed Services </h4>
                            <p className='m-4 mt-5'>A single easy-access and integrated platform to optimize your telecom operations for best customer service and simplified 
                            service delivery. <br/>We have worked on both sides of the table on the customer side and the consulting side and have unique perspectives and 
                            skill sets when it comes efficiently managing, maintaining, and innovating your ServiceNow environment.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container text-center p-3 mt-4 about--quotes'>
                <p className='m-3 text-center' style={{fontWeight: 400}}>To maximize the full business potential of our client's ServiceNow journey through tailored solutions and process expertise.</p>
            </div>
            <div className='container mt-5 text-center'>
                
            </div>
            <Footer />
        </>

    )
}