import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import IntroDesign from './IntroDesign';
import pageimages from '../images/all the page4.jpg';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function employeeworkflows(){
    return (
        <>
            <Navbar />
            <div className='text--product'>
                <span className='m-3 card--heading'>EMPLOYEE WORKFLOWS </span>
            </div>
            <IntroDesign />
                <div className='container mt-5 text-center'>
                    <p className='m-3 text-center'style={{fontWeight: 550}}>Make your employee experience highly memorable by automating all internal requests and execution. Lift up employee loyality by fulfilling their needs before time</p>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-sm-4 d-flex justify-content-center  '>
                            <div className='col-sm-12 m-5 itworkflow--content'>
                                <h4 className='m-5 mt-5'>HR Service Delivery</h4>
                                <p className='m-4 mt-5'>Automated workflows improve productivity and elevate employee satisfaction by optimizing service delivery and ease in access to service</p>
                                <h5 className='m-5 mt-5'>Key Capabilities</h5>
                                <div className='m-5 mt-5'>
                                    <ArrowRightIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}>Case & Knowledge Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Enterprise Onboarding</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Virtual Agent Chatbot</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Employee Document Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Mobile Onboarding App</span><br/>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4  d-flex justify-content-center'>
                            <div className='col-sm-12 m-5 itworkflow--content'>
                                <h4 className='m-5 mt-5'>Workplace Service Delivery</h4>
                                <p className='m-5 mt-5'>Multi-channel mobile enabled solution to automate all requests related to repairs and reservations for running a frictionless workplace operation</p>
                                <h5 className='m-5 mt-5'>Key Capabilities</h5>
                                <div className='m-5 mt-5'>
                                    <ArrowRightIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}>Workplace Space Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Workplace Reservation Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Case and Knowledge Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Workplace Space Mapping</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Workplace Visitor Managements</span><br/>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4  d-flex justify-content-center'>
                            <div className='col-sm-12 m-5 itworkflow--content'>
                                <h4 className='m-5 mt-5'> Procurement Service Management (ITBM) </h4>
                                <p className='m-5 mt-5'>A powerful productivity booster that allows for smooth and an unflawed procurement practice. Automation and digitization of buying processes empowers employees for a seamless business experience </p>
                                <h5 className='m-5 mt-5'>Key Capabilities</h5>
                                <div className='m-5 mt-5'>
                                    <ArrowRightIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}>Employee Service Center</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Purchase and Receipt Automation</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Reports and Dashboards</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>Knowledge Management</span><br/>
                                    <ArrowRightIcon  style={{ fontSize: "28px" , color: '#157CDC'}}/><span style={{fontWeight: 550}}>ShoppingHub</span><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-6 mt-5 text-center about--image--animation'>
                    <img src={pageimages} id='animation' className='about--image' alt="network" width="60%" height="100%" />
                    </div>
                    <div className='col-sm-6 mt-5 about--text--animation'>
                        <h4 className='text-center'> In-Depth Capability and Proficiency </h4>
                        <p className='mt-5'style={{fontWeight: 550}}>Employ our ServiceNow consulting services approach with Digital Workflows to deliver seamless technology at every touch point in your enterprise.</p><br/>
                        <DoubleArrowIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}> One stop-shop for all end users – A self-service portal for all service requests, and incident reporting for various business departments</span><br/>
                        <br/>
                        <DoubleArrowIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}> Seamless tracking of status requests and incidents, and knowledge-base for quick workaround and self-help</span><br/>
                        <br/>
                        <DoubleArrowIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}> Workflow automation with zero manual-updating procedures, and advanced work assignments to right agent</span><br/>
                        <br/>
                        <DoubleArrowIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}> Tracking of KPIs related to SLA compliance, request volume, approval status, and associated financial costs</span><br/>
                        <br/>
                        <DoubleArrowIcon  style={{ fontSize: "28px", color: '#157CDC' }}/><span style={{fontWeight: 550}}> Reduction in MTTR of incidents and increase in self-service index</span><br/>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    ) 
}