import React from 'react';
import Footer from '../Footer';
import Navbar from '../Navbar';
import Purpose from '../About/purpose';
import Vision from '../About/vision';
import Mission from '../About/mission';
import Video from '../video';
import expertise from './team.jpg';
import centricity from './reunion.jpg';
import innovation from './rocket.jpg';
import integrity from './career blue boder.jpg';
import agility from './hands.jpg';
import Core from './coreValues';
import Gallery from './gallery';

export default function Whoweare() {
    return (
        <>
            <Navbar />
            <h1 className='col-sm-3 about--content--image title--edit p-4'>WHO WE ARE</h1>
            <div className=''>
                <h5 className='col-sm-6 mt-4 title--edit p-3 about--content--text'>Step into the Future with DOTSITO Technologies - Your Pathway to IT Solutions and Unleashed Innovation! 
                Join us as we unveil the defining core values and principles that fuel our cutting-edge IT startup, dedicated to empowering innovation.</h5>
            </div>
            {/* <Video /> */}
            <div className='about--background--image'>
            </div>
            <Purpose />
            <Vision />
            <Mission />
            <div className='col-sm-12 p-3 mt-5 who--quotes--head'>
                <p>Core Values</p>
                <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
            </div>
            {/* <div className='col-sm-12 core-align p-5'>
                <div className='row'>
                    <div className='col-sm-4'>
                        <img src={expertise} width={300} style={{borderRadius: "5px"}} alt="" />
                        <p style={{fontSize:"25px"}}>Expertise</p>
                        <p style={{fontWeight: 400,fontSize:"18px"}}>  Our team comprises top-tier professionals with a diverse range of skills and knowledge.</p>
                    </div>
                    <div className='col-sm-4 f-align'>
                        <img src={centricity} width={300} style={{borderRadius: "5px"}} alt="" />
                        <p style={{fontSize:"25px"}}>Focus</p>
                        <p style={{fontWeight: 400,fontSize:"18px"}}> Our clients are at the heart of everything we do. We listen, understand, and prioritize their needs to deliver tailored 
                        solutions that exceed expectations.</p>
                    </div>
                    <div className='col-sm-4 i-align'>
                        <img src={innovation} width={300} style={{borderRadius: "5px"}} alt="" />
                        <p style={{fontSize:"25px"}}>Innovation</p>
                        <p style={{fontWeight: 400,fontSize:"18px"}}>  Embracing change and staying ahead of the curve is in our DNA. We continuously explore new technologies and industry trends 
                        to offer innovative solutions that give our clients a competitive edge.</p>
                    </div>
                    <div className='col-sm-4 a-align'>
                        <img src={integrity} width={300} style={{borderRadius: "5px"}} alt="" />
                        <p style={{fontSize:"25px"}}>Integrity</p>
                        <p style={{fontWeight: 400,fontSize:"18px"}}>  Honesty and transparency form the foundation of our interactions with clients, partners, and team members. We believe 
                        in building trust through ethical business practices.</p>
                    </div>
                    <div className='col-sm-4'>
                        <img src={agility} width={300} style={{borderRadius: "5px"}} alt="" />
                        <p style={{fontSize:"25px"}}>Agility</p>
                        <p style={{fontWeight: 400,fontSize:"18px"}}>  In the fast-paced world of technology, adaptability is crucial. We pride ourselves on our ability to be agile and responsive
                        to the ever-changing demands of the industry.</p>
                    </div>
                </div>
            </div> */}
            <Core />
            {/* <Gallery /> */}
            <Footer />
        </>
    );
}