import Navbar from './Navbar';
import Footer from './Footer';

export default function ITService(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7 text-center itservice--content--image title--edit p-3'>IT Security Service</h1>
                </div>
                <h5 className='col-sm-10 mt-4 itservice--content--text title--edit p-3'>To enhance the overall security posture and operational efficiency of the organization. These practices are crucial for safeguarding sensitive data, 
                protecting against cyber threats, and optimizing IT operations.</h5>
                <div className='itservice--background--image'>
                </div>
                <div className='col-sm-12 text-center mt-5 about--quotes--head'>
                    <p>IT Security Services</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itservice--practice--align'>
                                <p style={{fontSize:"25px"}}>Data Protection</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>IT security services help safeguard sensitive data from unauthorized access, theft, or manipulation. This protection is crucial for maintaining the trust of
                                 customers and complying with data protection regulations.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itservice--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Threat Detection and Prevention</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Implementing security services enables the detection and prevention of various cyber threats, such as malware, ransomware, phishing attacks, and intrusion attempts.
                                 It helps to identify potential security breaches before they cause significant damage.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itservice--practice--align'>
                                <p style={{fontSize:"25px"}}>Incident Response</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Having robust IT security services in place ensures that the company is prepared to respond effectively to security incidents. Quick and well-coordinated incident 
                                response minimizes the damage caused by potential breaches.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itservice--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Security Awareness and Training</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> IT security services often include security awareness programs and training for employees. Educated employees are better equipped to recognize and respond to security threats, 
                                reducing the likelihood of human error leading to security breaches.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 itservice--practice--align'>
                    <p style={{fontSize:"25px"}}>Risk Management</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}> IT security services include risk assessments and vulnerability scanning, which help identify weaknesses in the IT infrastructure and applications. By understanding and addressing these
                         risks proactively, the company can minimize the likelihood and impact of security incidents.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 itservice--key--style'>
                    <div className='itservice--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of IT Security Service</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itservice--practice--align'>
                                <p style={{fontSize:"18px"}}>Security Incident Response</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itservice--practice--align'>
                                <p style={{fontSize:"18px"}}>Event Management</p>
                                <p className='col-sm-1' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itservice--practice--align'>
                                <p style={{fontSize:"18px"}}>Vulnerability Response</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itservice--practice--align'>
                                <p style={{fontSize:"18px"}}>Threat Intelligence</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}