import Navbar from './Navbar';
import Footer from './Footer';
import React from 'react';

export default function TermsOfUse(){
    return(
        <div>
            <Navbar />
            <div className='esm--head'>
                <h1 className='col-sm-5 text-center privacy--content--image title--edit p-3'>Terms of Use</h1>
            </div>
            <div className='terms--background--image'>
            </div>
            <div className='container'>
                <h2 className='mt-5'>Dotsito Terms of Use</h2>
                <p style={{fontWeight: 500}} className='mt-2'>At Dotsito, Terms of Use means set of legal agreements and guidelines that outline the rules, responsibilities, and expectations between the company and its clients or users of its services.
                 These terms are designed to protect both parties and provide a clear understanding of the services being offered and the conditions under which they are provided.</p>

                <div className='m-4'>
                    <h4>1 . Intellectual property</h4>
                    <p style={{fontWeight: 500}}>All intellectual property rights in the products and services offered by Dotsito Technologies, including but not limited to copyrights, trademarks, 
                    patents, and trade secrets, are owned by Dotsito Technologies.Users of the products and services are granted a limited, non-exclusive license to use the products 
                    and services for their own personal or business purposes.</p>
                    <h4>2 . Data protection</h4>
                    <p style={{fontWeight: 500}}>Dotsito Technologies will collect and use personal data about users of its products and services in accordance with its privacy policy. Users have the right to access, 
                    correct, and delete their personal data.</p>
                    <h4>3 . Payment terms</h4>
                    <p style={{fontWeight: 500}}>Users must pay for the products and services offered by Dotsito Technologies in accordance with the payment terms set forth in Dotsito Technologies 
                    website or other documentation.</p>
                    <h4>4 . Refund policy</h4>
                    <p style={{fontWeight: 500}}>Users must pay for the products and services offered by Dotsito Technologies in accordance with the payment terms set forth in Dotsito Technologies 
                    website or other documentation.</p>
                    <h4>5 . Liability</h4>
                    <p style={{fontWeight: 500}}>Dotsito Technologies is not liable for any damages caused by the use of its products or services, including but not limited to direct, indirect, 
                    incidental, consequential, or punitive damages.</p> 
                </div>
                <h3 className='mt-5'>Governing law</h3>
                <p style={{fontWeight: 500}}>These terms and conditions are governed by the laws of the Republic of India.</p>
                <h3 className='mt-5'>Additional terms and conditions</h3>
                <p style={{fontWeight: 500}}>In addition to the above terms and conditions, the following additional terms and conditions apply to Dotsito Technologies' ServiceNow and Project Management Services</p>
                <div className='m-4'>
                    <h4>1 . Age of majority</h4>
                    <p style={{fontWeight: 500}}>Only users who are at least the age of majority in India may use the ServiceNow Integration and Project Management Services offered by Dotsito Technologies.</p>
                    <p style={{fontWeight: 500}}>Users may not use the ServiceNow Integration and Project Management Services offered by Dotsito Technologies for any illegal or unauthorized purpose.</p>
                    <h4>3 . Modification of terms and conditions</h4>
                    <p style={{fontWeight: 500}}>Dotsito Technologies may modify these terms and conditions at any time by posting the modified terms and conditions on its website. Users are responsible for reviewing
                     the terms and conditions periodically to ensure that they are aware of any changes.</p>
                    <h4>4 . Dispute resolution</h4>
                    <p style={{fontWeight: 500}}>Any disputes arising out of or relating to these terms and conditions will be resolved by binding arbitration in accordance with the rules of the Indian Council of Arbitration.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}