import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import quotes from '../images/quotes.png';
import { useState } from 'react';

// import FileSaver from 'file-saver';

import { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function Contact() {
   
    const [name, setName] = useState('');
    const [lname,setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [text,setArea] = useState('');

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_s63pkce', 'template_9zcuhzg', form.current, 'KoIzmoUhOlevuNVRO')
        .then((result) => {
            console.log(result.text);
            console.log("message sent");
            setName(' ');
            setLastName(' ');
            setEmail(' ');
            setPhone(' ');
            setArea(' ');

        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <>
            <Navbar />
            <h1 className='col-sm-6 contact--content--image'><span className='title--edit p-3'>Contact Us</span></h1>
            <h5 className='col-sm-3 contact--content--text'><span className='title--edit p-3'>Stay together and wins together</span></h5>
            <div className='contact--background--image'>
            </div>

            <div className='container text-center p-3 mt-5 about--quotes'>
                <img src={quotes} width={50} />
                <p style={{fontWeight: 400}}>We celebrate our employees as family. Providing best-of-breed knowledge enviroment to enquire at <span style={{color: "blue"}}>operations@dotsito.com</span> to chase your dreams.</p>
            </div>

            {/* <div className='col-sm-12 text-center mt-5'>
                <a href="mailto:operations@dotsito.com"><button className='mt-3 btn-sm content--services--button'>Share Resume</button></a>
            </div> */}

            

            <div className='container mt-5'>
                <form className='career--form' ref={form} onSubmit={sendEmail}>
                    <div className='col-sm-12 about--quotes--head'>
                        <p>Message to Dotsito</p>
                        <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='col-sm-12 mt-4'>
                        <div className='row'>
                            <div className="col-sm-6">
                                <label for="exampleInputEmail1" className="form-label">FirstName</label>
                                <input type="text"  name="name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
                            </div>
                            <div className="col-sm-6">
                                <label for="exampleInputPassword1" className="form-label">Lastname</label>
                                <input type="text" name="lname" value={lname} onChange={(e) => setLastName(e.target.value)} className="form-control" id="exampleInputPassword1" />
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 col-sm-12'>
                        <div className='row'>
                            <div className="col-sm-6">
                                <label for="exampleInputEmail1" className="form-label">Email</label>
                                <input type="email" value={email} name='email' onChange={(e) => setEmail(e.target.value)} className="form-control" required />
                            </div>
                            <div className="col-sm-6">
                                <label for="exampleInputPassword1" className="form-label">Phone</label>
                                <input type="text" value={phone} name='phone' onChange={(e) => setPhone(e.target.value)} className="form-control" id="exampleInputPassword1" />
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 col-sm-12">
                        <label for="exampleInputEmail1" className="form-label">Message</label>
                        <textarea value={text} name='message' onChange={(e) => setArea(e.target.value)} className="form-control" />
                    </div>
                    {/* <div className="mt-3 col-sm-12">
                        <label for="exampleInputEmail1" className="form-label">Resume</label>
                        <input type="file" value={rsfile} onChange={(e) => setFile(e.target.value)} className="form-control" required />
                    </div> */}
                    <div className='mt-3 col-sm-12 text-center'>
                        <button className='mt-3 btn-sm content--button' type="submit">Submit</button>
                    </div>
                    <br />
                </form>
            </div>


            <Footer />
        </>
    )
};