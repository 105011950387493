import Navbar from './Navbar';
import Footer from './Footer';
import React from 'react';

export default function Privacy(){
    return(
        <div>
            <Navbar />
            <div className='esm--head'>
                <h1 className='col-sm-5 text-center privacy--content--image title--edit p-3'>Privacy Policy</h1>
            </div>
            <div className='privacy--background--image'>
            </div>
            <div className='container'>
                <h2 className='mt-5'>Dotsito Privacy Policy</h2>
                <p style={{fontWeight: 500}} className='mt-2'>At Dotsito, we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines the types of information we collect,
                    how we use and protect it, and your choices regarding your data. By using our services and accessing our website, you consent to the practices described in this 
                    Privacy Policy.</p>

                <h3>1 . Information We Collect</h3>
                <div className='m-4'>
                    <h4>1.1. Personal Information</h4>
                    <p style={{fontWeight: 500}}>We may collect personal information that you provide to us when using our services or communicating with us. This may include your name, email address, contact number, postal address, 
                        and other relevant details.</p>
                    <h4>1.2. Usage Data</h4>
                    <p style={{fontWeight: 500}}>When you interact with our website or use our services, we may collect usage data, such as your IP address, browser type, operating system, pages visited, and the duration of 
                    your visit.</p>
                    <h4>1.3. Cookies and Similar Technologies</h4>
                    <p style={{fontWeight: 500}}>We use cookies and similar technologies to enhance your browsing experience and gather information about how you use our website. You can manage your preferences for cookies 
                    through your browser settings.</p>
                </div>
                <h3>2 . How We Use Your Information</h3>
                <div className='m-4'>
                    <h4>2.1. Providing Services</h4>
                    <p style={{fontWeight: 500}}>We may use your personal information to deliver the services you have requested, such as IT consulting, software development, or technical support.</p>
                    <h4>2.2. Communication</h4>
                    <p style={{fontWeight: 500}}>We may use your contact information to respond to your inquiries, provide updates about our services, and send you promotional materials if you have
                     opted in for such communications.</p>
                    <h4>2.3. Improving Our Services</h4>
                    <p style={{fontWeight: 500}}>We analyze usage data to improve our services, enhance user experience, and optimize the performance of our website.</p>
                    <h4>2.4. Legal Obligations</h4>
                    <p style={{fontWeight: 500}}>We may process your personal information to comply with legal obligations or respond to lawful requests from authorities.</p>
                </div>
                <h3>3 . Information Sharing</h3>
                <div className='m-4'>
                    <h4>3.1. Service Providers</h4>
                    <p style={{fontWeight: 500}}>We may share your data with trusted third-party service providers who assist us in delivering our services, but only to the extent necessary 
                    for them to perform their tasks.</p>
                    <h4>3.2. Business Partners</h4>
                    <p style={{fontWeight: 500}}>In some cases, we may collaborate with business partners or affiliates to offer joint services. If you choose to engage in such services, 
                    your information may be shared with the partner as required to provide the service.</p>
                    <h4>3.3. Legal Requirements</h4>
                    <p style={{fontWeight: 500}}>We may share your information if required by law, regulation, or legal process, or to protect the rights, property, or safety of our company, clients, or others.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}