import Navbar from './Navbar';
import Footer from './Footer';

export default function GRC(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-8 text-center grc--content--image title--edit p-3'> Governance, Risk and Compliance </h1>
                </div>
                <h5 className='col-sm-10 mt-4 grc--content--text title--edit p-3'>To establish a framework that ensures the organization operates responsibly, mitigates risks effectively, 
                and complies with relevant laws, regulations, and industry standards. GRC practices are crucial for 
                maintaining the company's reputation, protecting its assets and data, and building trust with clients and stakeholders.</h5>
                <div className='grc--background--image'>
                </div>
                <div className='col-sm-12 text-center mt-5 about--quotes--head'>
                    <p>Governance, Risk and Compliance (GRC)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 grc--practice--align'>
                                <p style={{fontSize:"25px"}}>Risk Management Oversight</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Governance oversees risk management practices, ensuring that potential risks are identified, assessed, and addressed in a systematic manner. It helps the company 
                                maintain a balanced risk posture while pursuing business opportunities.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 grc--practice--align'>
                                <p style={{fontSize:"25px"}}>Risk Identification</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>The risk management component of GRC helps identify potential threats and vulnerabilities that could impact the organization's IT systems, 
                                data, or operations.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 grc--practice--align'>
                                <p style={{fontSize:"25px"}}>Resource Allocation</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Governance helps allocate resources (financial, human, and technological) efficiently to IT projects and initiatives based on their importance and 
                                potential impact on the organization.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 grc--practice--align'>
                                <p style={{fontSize:"25px"}}>Audit Readiness</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> A strong compliance program prepares the organization for internal and external audits, ensuring that processes and controls are well-documented 
                                and followed.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 grc--practice--align'>
                    <p style={{fontSize:"25px"}}>Ethical Standards</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}> Compliance encompasses adherence to ethical principles and guidelines, ensuring that the company operates responsibly and transparently.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 grc--key--style'>
                    <div className='grc--practice--align mt-5'>
                        <p style={{fontSize:"25px"}}>Key Capabilities Of GRC</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-4 grc--practice--align'>
                                <p style={{fontSize:"18px"}}>Risk Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 grc--practice--align'>
                                <p style={{fontSize:"18px"}}>Vendor Risk Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-4 grc--practice--align'>
                                <p style={{fontSize:"18px"}}>Audit Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 grc--practice--align'>
                                <p style={{fontSize:"18px"}}>Policy and Compliance Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 grc--practice--align'>
                                <p style={{fontSize:"18px"}}>Business Continuity Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}