import Navbar from './Navbar';
import Footer from './Footer';

export default function ITAM(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7 text-center itam--content--image title--edit p-3'>IT Asset Management</h1>
                </div>
                <h5 className='col-sm-10 mt-4 itam--content--text title--edit p-3'>Effectively manage and optimize the lifecycle of IT assets throughout the organization. ITAM provides a structured approach to acquire,
                 deploy, maintain, and retire IT assets, such as hardware, software, licenses, and related resources.</h5>
                <div className='itam--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p>IT Asset Management (ITAM)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itam--practice--align'>
                                <p style={{fontSize:"25px"}}>Cost Optimization</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> IT assets represent a significant investment for a company. ITAM helps track and manage these assets, ensuring that they are utilized efficiently and
                                cost-effectively. By optimizing asset usage, the company can reduce unnecessary expenditures and improve overall financial management.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itam--practice--align'>
                                <p style={{fontSize:"25px"}}>License Compliance</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>ITAM ensures that software licenses are properly managed and aligned with the organization's needs. It helps prevent over-licensing or under-licensing, 
                                thereby minimizing the risk of non-compliance with software vendors' licensing agreements.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itam--practice--align'>
                                <p style={{fontSize:"25px"}}>Risk Reduction</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Proper management of IT assets reduces security and operational risks. ITAM helps identify outdated or vulnerable software versions, 
                                ensuring timely updates and patching to mitigate potential security threats.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 itam--practice--align'>
                                <p style={{fontSize:"25px"}}>Budgeting and Planning</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> Having a detailed view of IT assets and their lifecycle allows for better budgeting and planning. ITAM helps identify upcoming asset renewals,
                                 replacements, or upgrades, enabling the company to allocate resources appropriately.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 itam--practice--align'>
                    <p style={{fontSize:"25px"}}>Compliance and Audits</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}> ITAM ensures that the organization is compliant with regulatory requirements and internal policies related to IT assets. This readiness is beneficial during internal and external audits.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 itam--key--style'>
                    <div className='itam--practice--align mt-5'>
                        <p style={{fontSize:"25px"}}>Key Capabilities Of ITAM</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itam--practice--align'>
                                <p style={{fontSize:"18px"}}> Hardware Asset Management </p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itam--practice--align'>
                                <p style={{fontSize:"18px"}}>Saas License Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 itam--practice--align'>
                                <p style={{fontSize:"18px"}}>Software Asset Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}