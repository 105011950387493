import Navbar from './Navbar';
import Footer from './Footer';

export default function ITAM(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7text-center wsd--content--image title--edit p-3'>Workplace Service Delivery</h1>
                </div>
                <h5 className='col-sm-10 mt-4 wsd--content--text title--edit p-3'>Provide a seamless and efficient workplace experience for employees by managing and delivering a wide range of workplace services. 
                Workplace Service Delivery aims to enhance employee productivity, satisfaction, and collaboration while ensuring a safe and conducive work environment.</h5>
                <div className='wsd--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p>Workplace Service Delivery</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 wsd--practice--align'>
                                <p style={{fontSize:"25px"}}>Service Desk and Support</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> Workplace Service Delivery operates a service desk to handle employee requests and support for IT-related issues, facilities,
                                 and general inquiries.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 wsd--practice--align'>
                                <p style={{fontSize:"25px"}}>IT Device Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Workplace Service Delivery manages the deployment, maintenance, and support of IT devices such as laptops, desktops, tablets, 
                                smartphones, and other endpoints.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 wsd--practice--align'>
                                <p style={{fontSize:"25px"}}>Collaboration Tools</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>Workplace Service Delivery enables and supports collaboration tools and platforms that facilitate communication and teamwork among employees.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 wsd--practice--align'>
                                <p style={{fontSize:"25px"}}>Visitor Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> Workplace Service Delivery handles visitor registration and access control to the office premises.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 wsd--practice--align'>
                    <p style={{fontSize:"25px"}}>Workplace Health and Safety</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}> Workplace Service Delivery plays a role in promoting and enforcing workplace health and safety measures, ensuring a safe and compliant work environment.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 wsd--key--style'>
                    <div className='wsd--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of Workplace Service Delivery</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 wsd--practice--align'>
                                <p style={{fontSize:"18px"}}> Workplace Space Management </p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 wsd--practice--align'>
                                <p style={{fontSize:"18px"}}>Workplace Reservation Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 wsd--practice--align'>
                                <p style={{fontSize:"18px"}}>Case and Knowledge Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 wsd--practice--align'>
                                <p style={{fontSize:"18px"}}>Workplace Space Mapping</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 wsd--practice--align'>
                                <p style={{fontSize:"18px"}}>Workplace Visitor Managements</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}