import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CopyrightIcon from '@mui/icons-material/Copyright';
import privacyIcon from '../images/privacy-preferences-icon.png'
export default function Footer() {
    return (
        <>
            <div className='container col-sm-12 footer--border mt-5'>
            </div>
            <div className='footer--body footer--image'>
                <br />
                <br />
                <div className='container-fluid mt-5'>
                    <div className='col-sm-20 row'>
                        <div className='col-sm-3 text-center'>
                            <h6>Company</h6>
                            <br />
                            <div className='footer--content'>
                                <a href='/'><li>Home</li></a>
                                <br />
                                <a href='/about/whoweare'><li>About</li></a>
                                <br />
                                <a href='/solution/esm'><li>Solutions</li></a>
                                <br />
                                <a href='/services'><li>Services</li></a>
                                <br />
                                <a href='/contact'><li>Contact</li></a>
                                <br />
                                <a href='/careers'><li>Careers</li></a>
                                <br />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className='col-sm-3'>
                            <div className='col-sm-6'>
                                <h6 className='text-center'>Contact</h6>
                            </div>
                            <br />
                            <div className='footer--content footer--contact--only'>
                                <a href=''><li><span className="fa fa-shopping-cart footer--icon" aria-hidden="true"></span>&nbsp;&nbsp;Sales & Support</li></a>
                                <br />
                                <a href=''><li><span className="fa fa-building-o footer--icon" aria-hidden="true"></span>&nbsp;&nbsp;Dotsito Technologies</li></a>
                                <br />
                                {/* <a href=''><li><span className="fa fa-map-marker footer--icon" aria-hidden="true"></span>&nbsp;&nbsp;Maidenhead, UK<br/><span className="fa fa-phone footer--icon" aria-hidden="true"></span> +44 7404120188</li></a> */}
                                {/* <br/> */}
                                <a href=''><li><span className="fa fa-map-marker footer--icon" aria-hidden="true"></span>&nbsp;&nbsp;USA<br/><span className="fa fa-phone footer--icon" aria-hidden="true"></span> +1 (434) 602-2654</li></a>
                                <br/>
                                <a href=''><li><span className="fa fa-map-marker footer--icon" aria-hidden="true"></span>&nbsp;&nbsp;Uk<br/><span className="fa fa-phone footer--icon" aria-hidden="true"></span> +44 7587390684</li></a>
                                <br/>
                                {/* <a href=''><li><span className="fa fa-map-marker footer--icon" aria-hidden="true"></span>&nbsp;&nbsp;Australia<br/><span className="fa fa-phone footer--icon" aria-hidden="true"></span> +61-411117160</li></a> */}
                                {/* <br/> */}
                                <a href=''><li><span className="fa fa-map-marker footer--icon" aria-hidden="true"></span>&nbsp;&nbsp;No.115, Old Court Street, Akilandapuram, Kangeyam,<br/> Tiruppur District, Tamil Nadu-638701,<br/>India<br/><span className="fa fa-phone footer--icon" aria-hidden="true"></span> +91 4257 299473, +914257358130</li></a>
                                <br />
                                <a href=''><li><span className="fa fa-envelope footer--icon" aria-hidden="true"></span>&nbsp;&nbsp;operations@dotsito.com</li></a>
                                <br />
                            </div>
                        </div>
                        <div className='col-sm-2 text-center'>
                            <h6>Others</h6>
                            <br />
                            <div className='footer--content'>
                                <a href='/Terms&Conditions'><li>Terms of Use</li></a>
                                <br />
                                <a href="/PrivacyPolicy" ><li>Privacy Policy <img src={privacyIcon} width={30}></img></li></a>
                                <br />
                                <a href='/CookiesPolicy'><li>Cookies Policy</li></a>
                                <br />
                            </div>
                        </div>
                        <div className='col-sm-4 text-center'>
                            <h6>Follow Us</h6>
                            <br />
                            <div className='footer--content'>
                                <a href='https://www.facebook.com/profile.php?id=100094367335336&mibextid=ZbWKwL' target="_blank" ><li><FacebookIcon style={{ color: "#3b5998" }} /></li></a>
                                <br />
                                <a href='https://twitter.com/DotsitoTech' target="_blank"><li><TwitterIcon style={{ color: "#00acee" }} /></li></a>
                                <br />
                                <a href='https://www.instagram.com/dotsitotechnologies/' target="_blank"><li><InstagramIcon style={{ color: "#C13584" }} /></li></a>
                                <br />
                                <a href='https://www.linkedin.com/company/dotsito/' target="_blank"><li><LinkedInIcon style={{ color: "#0A66C2" }} /></li></a>
                                <br />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='container h-12 text-center'>
                        <h6><CopyrightIcon style={{ fontSize: "medium" }} />  <span> Copyright Dotsito Technologies. All Rights Reserved. </span></h6>
                    </div>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </>
    )
}