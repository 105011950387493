export default {
    // "data":{
        "career":[
            {
                "name" : "Devops Engineer",
                "id"   : "DOT-2023-NO21",
                "date" : "7/8/23",
                "desc" : "We are seeking a skilled and passionate DevOps Engineer to join our dynamic team. The ideal candidate will have a strong background in software development, system administration, and automation. The DevOps Engineer will work closely with our development and operations teams to enhance our software delivery processes, streamline deployment pipelines, and optimize infrastructure.",
                "step1": "1 . Familiarity with anyone of the Scripting languages such as Bash, Python, or Ruby for writing automation scripts.",
                "step2": "2 . Experience with CI/CD tools like Jenkins, GitLab CI/CD, Travis CI, or CircleCI for automating build, test, and deployment pipelines.",
                "step3": "3 . Familiarity with containerization (Docker), and container orchestration (Kubernetes).",
                "step4": "4 . Proficiency in monitoring tools like Prometheus, Grafana, New Relic, or Datadog to ensure the health and performance of applications and infrastructure.",
                "step5": "5 . Knowledge of cloud platforms such as AWS, Azure, or Google Cloud is an added advantage.",
                "qualify" : "Bachelor's degree in Computer Science, Information Technology, or related field (or equivalent experience).",
                "location" : "Kangayam",
                "apply": "Apply"
            },
            {
                "name" : "Python Developer",
                "id"   : "DOT-2023-NO22",
                "date" : "7/8/23",
                "desc" : "We are seeking a skilled Python Developer to join our talented software development team. The ideal candidate is a proactive problem solver with a strong command of Python programming and a passion for creating high-quality software. The Python Developer will work closely with our development, design, and testing teams to deliver innovative and robust solutions.",
                "step1": "1 . Understanding of Python programming language fundamentals, including data types, variables, loops, functions, and object-oriented programming (OOP) concepts.",
                "step2": "2 . Experience with web frameworks such as Django or Flask for building robust and scalable web applications.",
                "step3": "3 . Experience with databases, including both relational databases (e.g., PostgreSQL, MySQL) and NoSQL databases (e.g., MongoDB, Redis).",
                "step4": "4 . Ability to design, develop, and consume RESTful APIs for seamless integration between different software components.",
                "step5": "5 . Knowledge of cloud platforms such as AWS, Azure, or Google Cloud is an added advantage.",
                "qualify" : "Bachelor's degree in Computer Science, Information Technology, or related field (or equivalent experience).",
                "location" : "Bangalore",
                "apply": "Apply"
            },
            {
                "name" : "ServiceNow Architect",
                "id"   : "DOT-2023-NO23",
                "date" : "7/8/23",
                "desc" : "We are seeking an experienced ServiceNow Architect to lead the design and implementation of ServiceNow solutions for our organization. The ideal candidate possesses in-depth technical expertise in ServiceNow platform capabilities, architecture, and best practices. The ServiceNow Architect will collaborate with cross-functional teams, provide technical guidance, and drive the successful execution of ServiceNow projects.",
                "step1": "1 . In-depth knowledge of the ServiceNow platform, including its various modules, features, and capabilities.",
                "step2": "2 . Expertise in designing and implementing solutions using ServiceNow's IT Service Management (ITSM), IT Operations Management (ITOM), Customer Service Management (CSM), HR Service Delivery, and other modules.",
                "step3": "3 . Strong architectural and design skills to create scalable, reliable, and high-performance ServiceNow solutions.",
                "step4": "4 . Ability to design workflows, automation processes, data models, and integrations to meet business requirements.",
                "step5": "5 . Experience with ServiceNow APIs, RESTful web services, and integration tools for connecting ServiceNow with external systems.",
                "qualify" : "Bachelor's degree in Computer Science, Information Technology, or related field (or equivalent experience).",
                "location" : "Kangayam",
                "apply": "Apply"
            },
            {
                "name" : "Tools Architect",
                "id"   : "DOT-2023-NO24",
                "date" : "7/8/23",
                "desc" : "We are seeking an experienced Tools Architect to lead the design, implementation, and management of software tools and automation solutions that drive efficiency and innovation across our organization. The ideal candidate possesses a deep understanding of tooling architectures, automation frameworks, and best practices for optimizing development and operations workflows.",
                "step1": "1 . Proficiency in a variety of testing tools such as Selenium, JUnit, TestNG, Jest, PyTest, or equivalent, for automated testing of web applications, APIs, and unit tests.",
                "step2": "2 . Experience with load testing tools like JMeter or Gatling to simulate heavy user loads and analyze system performance.",
                "step3": "3 . Collaborate with cross-functional teams, including development, operations, and business units, to understand tooling needs and requirements.",
                "step4": "4 . Design and implement software tools, automation frameworks, and systems that enhance productivity, streamline processes, and improve collaboration.",
                "step5": "5 . Develop and maintain technical documentation, including architectural diagrams, design specifications, and usage guidelines.",
                "qualify" : "Bachelor's degree in Computer Science, Information Technology, or related field (or equivalent experience).",
                "location" : "Bangalore",
                "apply": "Apply"
            },
            {
                "name" : "Java Developer",
                "id"   : "DOT-2023-NO25",
                "date" : "7/8/23",
                "desc" : "We are seeking a skilled Java Developer to join our dynamic software development team. The ideal candidate has a strong foundation in Java programming and a passion for creating high-quality software solutions. The Java Developer will collaborate with cross-functional teams, participate in the entire software development lifecycle, and contribute to the design and implementation of innovative applications.",
                "step1": "1 . Strong understanding of Java fundamentals, including object-oriented programming (OOP) concepts, data types, variables, control structures, and exception handling.",
                "step2": "2 . Familiarity with Java Standard Library classes and commonly used APIs, such as java.util, java.io, java.net, and others.",
                "step3": "3 . Proficiency in the Spring Framework, including Spring Boot, Spring MVC, Spring Data, and Spring Security for building robust and scalable applications.",
                "step4": "4 . Ability to design and implement RESTful APIs using frameworks like Spring Boot, and consume APIs using HTTP methods.",
                "step5": "5 . Design, develop, test, and maintain Java-based applications and software solutions.",
                "qualify" : "Bachelor's degree in Computer Science, Information Technology, or related field (or equivalent experience).",
                "location" : "Kangayam",
                "apply": "Apply"
            },
            {
                "name" : "Cloud Architect",
                "id"   : "DOT-2023-NO26",
                "date" : "7/8/23",
                "desc" : "We are seeking an experienced Cloud Architect to lead the design and implementation of cloud-based solutions that drive innovation and efficiency for our organization. The ideal candidate possesses a deep understanding of cloud technologies, architectures, and best practices, along with a proven track record of designing and delivering robust and scalable cloud solutions.",
                "step1": "1 . Proficiency in at least one major cloud platform, such as Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform (GCP), or others.",
                "step2": "2 . Ability to design and architect cloud solutions that are scalable, reliable, and cost-effective.",
                "step3": "3 . Familiarity with cloud architectural patterns, including microservices, serverless, containers, and hybrid/multi-cloud setups.",
                "step4": "4 . Lead the migration of on-premises applications and workloads to the cloud, ensuring a smooth and efficient transition.",
                "step5": "5 . Optimize cloud infrastructure for cost efficiency and performance, closely monitoring and managing resource usage.",
                "qualify" : "Bachelor's degree in Computer Science, Information Technology, or related field (or equivalent experience).",
                "location" : "Kangayam",
                "apply": "Apply"
            },
        ]
    // }
}