import React from 'react';

export default function Purpose() {
    return (
        <>
            <h1 className='col-sm-6 about--purpose--content--image'>OUR PURPOSE</h1>
            <p className='col-sm-2 about--purpose--text show' style={{borderBottom: "3px solid #fff"}}></p>
            <h5 className='col-sm-6 mt-4 about--purpose--text'>At DOTSITO, our purpose is to unleash the true potential of technology for businesses worldwide. With a passionate team of experts, we endeavor to create a positive impact on our clients operations, driving innovation, and fostering a collaborative ecosystem. Together, we build a world where technology becomes a seamless enabler of progress, leaving no room for limitations.</h5>
            <div className='about--purpose--image'>
            </div>
            {/* <div>
                <button className='purpose--button btn-sm'>Know more</button>
            </div> */}
           
        </>
    );
}