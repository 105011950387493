import Navbar from './Navbar';
import Footer from './Footer';

export default function ITAM(){
    return(
        <>
            <Navbar />
                <div className='esm--head'>
                    <h1 className='col-sm-7 text-center csm--content--image title--edit p-3'>Customer Service Management</h1>
                </div>
                <h5 className='col-sm-10 mt-4 csm--content--text title--edit p-3'>To provide exceptional customer support and service to clients, ensuring their satisfaction and building strong, long-lasting relationships.
                 CSM involves a set of practices, processes, and tools to effectively manage customer inquiries, issues, and requests, ultimately leading to increased customer loyalty and
                  business growth.</h5>
                <div className='csm--background--image'>
                </div>
                <div className='col-sm-12 mt-5 about--quotes--head'>
                    <p>Customer Service Management (CSM)</p>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 csm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Client Relationship Management</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> CSM focuses on building and nurturing positive relationships with clients, fostering trust and loyalty.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 csm--practice--align'>
                                <p style={{fontSize:"25px"}}>Customer Communication</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> CSM ensures clear and consistent communication with clients, keeping them informed about the status of their requests and providing 
                                updates on service-related matters.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 csm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Customer Feedback and Surveys</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}>CSM collects and analyzes customer feedback through surveys and other means, using insights to improve service quality.</p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-12 csm--practice--align'>
                                <p className="sms--key-cap" style={{fontSize:"25px"}}>Customer Training and Support</p>
                                <p className='col-sm-3 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                            <div>
                                <p style={{fontWeight: 400}}> CSM provides training and support to clients, helping them make the most of the IT services and solutions offered.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5 csm--practice--align'>
                    <p className="sms--key-cap" style={{fontSize:"25px"}}>Customer Escalation Management</p>
                    <p className='col-sm-2 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <div className='col-sm-10'>
                        <p style={{fontWeight: 400}}> CSM manages customer escalations, ensuring they are addressed promptly and escalated to appropriate levels when necessary.</p>
                    </div>
                </div>
                <div className='col-sm-12 p-3 mt-5 csm--key--style'>
                    <div className='csm--practice--align mt-5'>
                        <p className="sms--key-cap" style={{fontSize:"25px"}}>Key Capabilities Of Customer Service Management</p>
                        <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 csm--practice--align'>
                                <p style={{fontSize:"18px"}}>Case Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>   
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 csm--practice--align'>
                                <p style={{fontSize:"18px"}}>Knowledge Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 csm--practice--align'>
                                <p style={{fontSize:"18px"}}>Customer Project Management</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 csm--practice--align'>
                                <p style={{fontSize:"18px"}}>Task Intelligence</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='col-sm-6 mt-5'>
                            <div className='col-sm-6 csm--practice--align'>
                                <p style={{fontSize:"18px"}}>Agent Workspace</p>
                                <p className='col-sm-1 core--text--cap--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                            </div>
                        </div>
                        <div className='p-3'></div>
                    </div>
                </div>
            <Footer />
        </>
    )
}