import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import person1 from '../images/Kiruthika_new.png';
import person2 from '../images/aswathaman.png';
import person3 from '../images/suji.png';
export default function Leader(){
    return(
        <>
        <Navbar />
        <h1 className='col-sm-3 leader--content--image title--edit p-2'>Our Leadership</h1>
        <div className=''>
            <h5 className='col-sm-3 mt-4 leader--content--text title--edit p-3'>The way to get started is to quit talking and begin doing.</h5>
        </div>
        <div className='leader--background--image'>
        </div>
        <div className='col-sm-12 mt-5 about--quotes--head'>
            <p>Our Leadership</p>
            <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
        </div>
        <div className='col-sm-12 mt-5'>
            <div className='row'>
                <div className='col-sm-6 text-center leader--img'>
                    <img src={person1} width={400} />
                </div>
                <div className='col-sm-5 leader--text--align text-center'>
                    <h3 className='h3'>Kiruthika Sekar</h3>
                    {/* <h3 className='h3'>Director</h3> */}
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <p style={{fontWeight: 400}}>Kiruthika Sekar, as a Chief Executive Officer, Co-director and Chief Financial Officer, sets and evolves the strategic direction for the company and its portfolio of offerings, while nurturing a strong leadership team to drive its execution. Kiruthika Sekar has 13 years of global experience in the IT services industry with a strong track record of driving digital transformation for enterprises, executing business turnarounds and managing successful acquisitions. She holds a Bachelor of Engineering degree in Electrical and Electronics Engineering from Anna University, Chennai. </p>
                </div>
            </div>
        </div>
        <div className='col-sm-12 mt-5'>
            <div className='row core--mobile'>
                <div className='col-sm-5 leader--text--align text-center'>
                    <h3 className='h3'>Aswathaman Rangasamy</h3>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <p style={{fontWeight: 400}}>For over the course of his career , Aswathaman has demonstrated his effective management acumen  across a wide range of projects, honing his 
                    skills across various technologies. His dedication to continuous learning and staying updated with industry trends has positioned him as a leading expert in technology. 
                    This commitment to knowledge empowers him to present innovative solutions and consistently achieve exceptional results for his clients. Aswathaman's wealth of experience
                     and expertise makes him a valuable asset to any organization seeking top-tier performance and cutting-edge solutions. </p>
                </div>
                <div className='col-sm-6 leader--img text-center'>
                    <img src={person2} width={400} />
                </div>
            </div>
        </div>
        <div className='col-sm-12 mt-5'>
            <div className='row'>
                <div className='col-sm-6 leader--img text-center'>
                    <img src={person3} width={400} />
                </div>
                <div className='col-sm-5 text-center leader--text--align'>
                    <h3 className='h3'>Sujithra Govindasamy</h3>
                    <p className='col-sm-1 core--text--mobile' style={{borderBottom: "3px solid #0c68e0"}}></p>
                    <p style={{fontWeight: 400}}>Sujithra has garnered extensive expertise and excellence in Management, Marketing, and Media domains. Her astute attention
                     to detail and exceptional communication skills have made her a standout professional. What truly sets her apart is her unwavering passion for team building,
                      which she displays with enthusiasm and dedication. Alongside her remarkable team leadership abilities, she demonstrates exceptional organizational skills, 
                      enabling her to streamline processes and achieve efficient results. Sujithra's comprehensive skill set and years of experience make her a valuable asset in any 
                      organization seeking a competent and dynamic team player.</p>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}