import Navbar from './Navbar';
import Footer from './Footer';
import React from 'react';

export default function Cookies(){
    return(
        <div>
            <Navbar />
            <div className='esm--head'>
                <h1 className='col-sm-5 text-center privacy--content--image title--edit p-3'>Cookies Policy</h1>
            </div>
            <div className='cookie--background--image'>
            </div>
            <div className='container'>
                <h2 className='mt-5'>Dotsito Cookies Policy</h2>
                <p style={{fontWeight: 500}} className='mt-2'>Dotsito Technologies uses cookies on its website to improve your experience. 
                Cookies are small text files that are stored on your computer or mobile device when you visit a website. They are used to remember your preferences and settings, 
                such as your language and font size, and to collect information about how you use the website.
                </p>
                <p style={{fontWeight: 500}} className='mt-2'>Dotsito Technologies uses the following types of cookies:</p>
                <div className='m-4'>
                    <h4>1 . Strictly necessary cookies</h4>
                    <p style={{fontWeight: 500}}>These cookies are essential for the website to function properly. They allow you to browse the website and use its features.</p>
                    <h4>2 . Performance cookies</h4>
                    <p style={{fontWeight: 500}}>These cookies collect information about how you use the website, such as the pages you visit and the links you click. This information is used to improve the website's 
                    performance and to make sure that it is providing you with the best possible experience.</p>
                    <h4>3 . Targeting cookies</h4>
                    <p style={{fontWeight: 500}}>These cookies are used to track your browsing habits so that Dotsito Technologies can show you advertising that is relevant to your interests.</p>
                   
                    <p style={{fontWeight: 500}}>You can choose to disable cookies on your computer or mobile device. However, this may prevent you from using some of the features of the website.
                    </p>
                    <p>For more information about cookies, please visit the following websites:</p>
                    <p style={{fontWeight: 500}}> About Cookies: <a href="https://www.aboutcookies.org/">https://www.aboutcookies.org/</a></p> 
                    <p>If you have any questions about Dotsito Technologies' cookie policy, please contact us at operations@dotsito.com</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}