import * as React from 'react';
// import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Navbar from './Navbar';
// import Navbar1 from './Navbar1';
import Footer from './Footer';
import { Link, NavLink} from 'react-router-dom';
import itsm from '../images/itsm.png';
import itam from '../images/itam.png';
import itbm from '../images/itbm.png';
import itom from '../images/itom.png';
import devops from '../images/devops.png';
import grc from '../images/GRC.png'
import ss from '../images/security.png';
import hrs from '../images/hr.png';
import wsd from '../images/wsd.png';
import lsd from '../images/lsd.png';
import csm from '../images/csm.png';
import fsm from '../images/service.png';
import tsm from '../images/tsm.png';

export default function LabTabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

//   useEffect(() => {
//     const handleScroll = () => {
//       const fixedContent = document.querySelector('.fixed-content');
//       if (fixedContent) {
//         const container = document.querySelector('.container');
//         const containerRect = container.getBoundingClientRect();
//         const fixedContentRect = fixedContent.getBoundingClientRect();
//         const topOffset = 450; // Adjust this value based on your requirements

//         if (window.scrollY >= containerRect.top + topOffset) {
//           fixedContent.style.position = 'fixed';
//           fixedContent.style.top = `${topOffset}px`;
//           fixedContent.style.left = `${fixedContentRect.left}px`;
//         } else {
//           fixedContent.style.position = 'absolute';
//           fixedContent.style.top = `${containerRect.top + topOffset}px`;
//           fixedContent.style.left = `${containerRect.left}px`;
//         }
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

  return (
    <>
        <Navbar/>
        <div className='esm--head'>
        <h1 className='col-sm-8 text-center esm--content--image title--edit p-3'>Enterprise Service Management</h1>
        </div>
        <h5 className='col-sm-10 mt-4 text-center esm--content--text title--edit p-3'>ESM aims to improve efficiency, collaboration, and customer satisfaction by standardizing and automating service delivery 
        processes throughout the organization.</h5>
        <div className='esm--background--image'>
        </div>
        <div>
            <Box>
                <TabContext value={value}>
                    <div className='p-3 esm--content--head'>
                        <Box sx={{}}>
                            <TabList onChange={handleChange}>
                                <Tab label="IT Workflows" value="1" className='esm--font'/>
                                <Tab label="Employee Workflows" value="2" className='esm--font'/>
                                <Tab label="CustomerWorkflows" value="3" className='esm--font'/>
                            </TabList>
                        </Box>
                    </div>
                    <TabPanel value="1">
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={itsm} width={620} />
                                </div>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}>IT Service Management</p>
                                        <p style={{fontWeight: 400}}>A modern ITIL based service management solution which provides digital workflows to automate IT services and thereby 
                                        increase productivity, and achieve new insights.</p>
                                        <a href="/service/itsm"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row core--mobile'>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}>IT Asset Management Solutions</p>
                                        <p style={{fontWeight: 400}}>Drive full lifecycle tracking and visibility into hardware assets and software licenses with costs, processes and data 
                                        on a single platform.</p>
                                        <a href="/solution/itam"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>   
                                </div>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={itam} width={620} />
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={itbm} width={620} />
                                </div>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}> IT Business Management (ITBM)</p>
                                        <p style={{fontWeight: 400}}>Optimize project and application portfolio, cut IT costs, reduce time to market and align IT to the business strategies.</p>
                                        <a href="/service/itbm"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row core--mobile'>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}> IT Operations Management</p>
                                        <p style={{fontWeight: 400}}>Prevent service outages by gaining visibility into IT infrastructure and applications, event correlation to reduce NOC 
                                        workload, actionable operational intelligence and cloud insights.</p>
                                        <a href="/service/itom"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>
                                </div>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={itom} width={620} />
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={devops} width={620} />
                                </div>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}> DevOps </p>
                                        <p style={{fontWeight: 400}}>Unify Application Development and IT Operations teams with integrated CI-CD tool chain and change automation to minimize 
                                        Time to Market.</p>
                                        <a href="/solution/Devops"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row core--mobile'>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}> Governance Risk and Compliance</p>
                                        <p style={{fontWeight: 400}}>Build an integrated risk framework that connects IT, business and security and unify them on a single platform.</p>
                                        <a href="/solution/GRC"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>
                                </div>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={grc} width={620} />
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={ss} width={620} />
                                </div>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}> Security Operations</p>
                                        <p style={{fontWeight: 400}}>Quickly detect, map and resolve threats by bridging the gap between security and IT. Identify and prioritize security 
                                        incidents and avoid any service disruptions or potential failures.</p>
                                        <a href="/solution/ITSecurity"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={hrs} width={620} />
                                </div>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}>HR Service Delivery</p>
                                        <p style={{fontWeight: 400}}>Automated workflows improve productivity and elevate employee satisfaction by optimizing service delivery and ease in access to service.</p>
                                        <a href="/service/HRS"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row core--mobile '>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}>Workplace Service Delivery</p>
                                        <p style={{fontWeight: 400}}>Multi-channel mobile enabled solution to automate all requests related to repairs and reservations for running a frictionless workplace operation.</p>
                                        <a href="/service/WSD"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>   
                                </div>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={wsd} width={620} />
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={lsd} width={620} />
                                </div>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}>Legal Service Delivery</p>
                                        <p style={{fontWeight: 400}}>Primarily known for its IT Service Management capabilities, can also be extended to cater to the specific needs of legal departments in organizations. 
                                        By leveraging ServiceNow's workflow automation, collaboration, and reporting features, legal teams can efficiently manage legal matters, contracts, compliance, and other legal 
                                        processes.</p>
                                        <a href="/service/LSD"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="3">
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={csm} width={620} />
                                </div>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}>Customer Service Management</p>
                                        <p style={{fontWeight: 400}}>Enhance the customer satisfaction by leveraging AI driven self service support and fixing issues proactively with predictive intelligence.</p>
                                        <a href="/service/CSM"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row core--mobile'>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}>Field Service Management (FSM)</p>
                                        <p style={{fontWeight: 400}}>Adopt modernized FSM tools and increase the first time fix-in rate by empowering field teams with mobility and ability to connect to other teams.</p>
                                        <a href="/service/FSM"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>   
                                </div>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={fsm} width={620} />
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-sm-6 esm--life--content--image'>
                                    <img src={tsm} width={620} />
                                </div>
                                <div className='col-sm-6 mt-5'>
                                    <div className='col-sm-11 esm--life--content'>
                                        <p style={{fontSize:"25px"}}> Telecommunications Service Management</p>
                                        <p style={{fontWeight: 400}}>A single easy-access and integrated platform to optimize your telecom operations for best customer service and simplified service delivery.</p>
                                        <a href="/service/TSM"><button className='esm--button btn-sm'>Know more</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
        
        <Footer />
    </>
  );
}
