import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
// import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import BalanceIcon from '@mui/icons-material/Balance';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import SupportIcon from '@mui/icons-material/Support';
import code from '../images/code.png';
import computer from '../images/computer.png';
import working from '../images/working.png';
import CoreEdit from './Life/coreValue_edit';
import collaborative from '../images/collabrative.png';
import innovative from '../images/innovative.png';
import supportive from '../images/support.png';
export default function Life() {
    return (
        <main>
            <Navbar />
            <h1 className='col-sm-4 text-center life--content--image title--edit p-2'>LIFE @ DOTSITO</h1>
            <div className='life--background--image'>
            </div>
            {/* <div className='container p-3 text-center mt-5 life--content--about'>
                <p style={{fontWeight: 550}}>Dotsito Technologies is an Outcome-Focused, Global IT Consulting, Innovation and Services organization. Deliver outcome focused technology solutions that enhance business performance and organizational value of our customers, making a positive impact on our people and society.</p>
            </div> */}
            <div className='container-fluid life--body mt-5'>
                <div className='row'>
                    <div className='col-sm-6 mt-5'>
                        <div className='col-sm-6 life--content--font p-3'>
                            <p>What Makes <span style={{color: "#1D61E7"}}>Dotsito</span> the best workplace?</p> 
                             {/* 274083 #12439C */}
                        </div>
                    </div>
                    <div className='col-sm-6 mt-5 about--text--animation '>
                        <div className='col-sm-12'>
                            <div className='row'>
                                <div className='col-sm-1 mt-1'>
                                    <div className=' col-sm-8'> 
                                        <GroupsIcon style={{fontSize: "45px", color:"#1D61E7"}}/>
                                    </div> 
                                    {/* <div className='col-sm-3 line--design'>
                                    </div> */}
                                </div>
                                <div className='col-sm-10' >
                                    <h4>Employee Development</h4>
                                    <p  style={{fontWeight: 400}}> Offering opportunities for professional development, training, and career growth shows a commitment to employees' continuous improvement and helps them advance in their careers.</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='row'>
                                <div className='col-sm-1 mt-1'>
                                    <div className=' col-sm-8'> 
                                        <EmojiEventsIcon style={{fontSize: "45px", color:"#1D61E7"}} />
                                    </div> 
                                    {/* <div className='col-sm-4 line--design'>
                                    </div> */}
                                </div>
                                <div className='col-sm-10' >
                                    <h4>Recognition and Rewards</h4>
                                    <p  style={{fontWeight: 400}}> Acknowledging and rewarding employees' contributions and achievements, whether through verbal praise, bonuses, or incentives, boosts motivation and reinforces a sense of accomplishment</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='row'>
                                <div className='col-sm-1 mt-1'>
                                    <div className=' col-sm-8'> 
                                      <BalanceIcon  style={{fontSize: "45px", color:"#1D61E7"}} />
                                    </div> 
                                    {/* <div className='col-sm-4  line--design'>
                                    </div> */}
                                </div>
                                <div className='col-sm-10' >
                                    <h4>Work-Life Balance</h4>
                                    <p  style={{fontWeight: 400}}> Providing employees with flexible work arrangements, paid time off, and opportunities for remote work can lead to better work-life balance and reduce burnout.</p>
                                    <br/>
                                    {/* <h4>Workplace Safety</h4>
                                    <p  style={{fontWeight: 400}}> Ensuring a safe and healthy work environment through proper safety protocols and promoting mental health initiatives demonstrates care for employees' physical and mental well-being.</p>
                                    <br/> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='row'>
                                <div className='col-sm-1 mt-1'>
                                    <div className=' col-sm-8'> 
                                        <GolfCourseIcon style={{fontSize: "45px", color:"#1D61E7"}} />
                                    </div> 
                                    {/* <div className='col-sm-4  line--design'>
                                    </div> */}
                                </div>
                                <div className='col-sm-10' >
                                    <h4>Clear Goals and Expectations</h4>
                                    <p  style={{fontWeight: 400}}> Providing employees with clear goals and expectations helps them understand their role in achieving the company's mission and promotes a sense of purpose.</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='row'>
                                <div className='col-sm-1 mt-2'>
                                    <div className=' col-sm-8'> 
                                        <SupportIcon style={{fontSize: "45px", color:"#1D61E7"}} />
                                    </div> 
                                    {/* <div className='col-sm-4  line--design'>
                                    </div> */}
                                </div>
                                <div className='col-sm-10' >
                                    <h4>Supportive Management</h4>
                                    <p  style={{fontWeight: 400}}>  Having supportive and approachable managers who mentor, coach, and advocate for their team members can lead to increased job satisfaction and better performance.</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        {/* <span style={{fontWeight: 550}} > Dotsito Technologies specializes in delivering exceptional solutions on the ServiceNow platform. We pride ourselves on offering industry-leading services, unparalleled expertise, and a track record of positive customer reviews.</span>
                        <br/>
                        <br/> */}
                        {/* <div className='col-sm-12 text-center'>
                            <button className='mt-3 btn-sm content--button p-2'>Learn More</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='collabrative mt-5 p-4'>
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className='col-sm-6 about--life--content--image'>
                            <img src={collaborative} width={820} />
                        </div>
                        <div className='col-md-6 about--life--content'>
                            <div className='col-sm-11'>
                                <p style={{fontSize:"25px"}}>Collaborative Environment</p>
                                <p style={{fontWeight: 400}}>In Dotsito, Encourage teamwork and open communication among employees. Foster a culture where individuals from different teams and departments collaborate seamlessly to achieve common goals.
                                A collaborative environment values and recognizes the contributions of teams and individuals. 
                                Celebrating successes and acknowledging the efforts of collaborative projects boosts team morale and fosters a positive culture.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 mt-5'>
                    <div className='row core--mobile'>
                        <div className='col-sm-6 about--life--content'>
                            <div className='col-sm-11'>
                                <p style={{fontSize:"25px"}} className='ms-auto'>Innovation and Creativity</p>
                                <p style={{fontWeight: 400}}>Innovation and creativity play a crucial role in the success and growth of a company. As the IT industry rapidly evolves, staying ahead of the competition and delivering 
                                exceptional solutions to clients requires a strong focus on innovation and creative thinking. In Dotsito we encourages innovative thinking and creative problem-solving. Reward and recognize employees who come up with
                                innovative solutions for clients challenges.</p>
                            </div>
                        </div>
                        <div className='col-md-6 about--life--content--image'>
                            <img src={innovative} width={820} />
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 mt-5'>
                    <div className='row'>
                        <div className='col-sm-6 about--life--content--image'>
                            <img src={supportive} width={820} />
                        </div>
                        <div className='col-md-6 about--life--content'>
                            <div className='col-sm-11'>
                                <p style={{fontSize:"25px"}}>Supportive Leadership</p>
                                <p style={{fontWeight: 400}}>Supportive leadership is a crucial aspect of creating a positive and productive work environment in a company. It involves leaders who actively empower and assist their teams, 
                                fostering a culture of collaboration, growth, and employee well-being.In Dotsito we Trusting Teams,Open and Transparent Communication,Mentoring and Coaching,
                                Professional Growth Opportunities and Encouraging Innovation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CoreEdit />
            <div className='col-sm-12 mt-5 life--customer--feed--head'>
                <p>Customer Success !</p>
                <p className='col-sm-1' style={{borderBottom: "3px solid #0c68e0"}}></p>
            </div>
            <div className='container text-center p-3 mt-5 life--customer-feed'>
                <p style={{fontWeight: 400}}> Dotsito Technologies specializes in delivering exceptional solutions on the ServiceNow platform. We pride ourselves on offering industry-leading services, unparalleled expertise, and a track record of positive customer reviews.</p>
            </div>
            <Footer />
        </main>
    )
};